import { Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';
import { LoaderComponent } from '@t12/ui/components/loader/loader.component';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.less'],
    imports: [NgClass, NgIf, LoaderComponent]
})
export class ButtonComponent {
  @Input() disabled = false;
  @Input() label = '';
  @Input() kind: '' | 'validation' | 'warning' = '';
  @Input() state = '';
  @Input() svgName: string;
  @Input() loading: LoadingStatus;
  @Input() labelLoadingStatus: string;

  protected readonly LoadingStatus = LoadingStatus;
}
