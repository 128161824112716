import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { PlayerUpdateInfosActionSocket } from '@t12/common/player/constants/player-update-infos-action-socket.interface';

export const PlayerActions = createActionGroup({
  source: 'PLAYER',
  events: {
    Interaction: emptyProps(),
    'Interact tile': props<{ position: Position }>(),
    'Interact success': emptyProps(),
    'Pick item': props<{ position: Position }>(),
    'Get tile description': props<{ position: Position }>(),

    'Interact character': emptyProps(),
    'Init dialog': props<{ position: Position }>(),
    'Next dialog': props<{ position: Position }>(),

    'Player use an item': props<{
      updateInfos: PlayerUpdateInfosActionSocket;
    }>(),
  },
});
