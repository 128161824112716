import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getPlayer,
  getPlayerName,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatTab } from '@t12/chat/enums/chat-tab.enum';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { InventoryDbService } from '@t12/inventory/services/inventory-db/inventory-db.service';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getPlayerItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { getTileInFrontOfPlayer } from '@t12/world/store/selector/world.selectors';
import {
  filter,
  forkJoin,
  map,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class InventoryDropEffects {
  private _dropItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.dropItem),
      throttleTime(200),
      filter(({ amount }) => amount > 0),
      switchMap((action) =>
        forkJoin({
          player: this._store.select(getPlayer).pipe(take(1)),
          tile: this._store.select(getTileInFrontOfPlayer).pipe(take(1)),
          item: this._store
            .select(getPlayerItemInventory(action.itemCode))
            .pipe(take(1)),
        }).pipe(
          map(({ player, tile, item }) => ({
            action,
            player,
            tile,
            item,
          })),
        ),
      ),
      map(({ action, player, tile, item }) => {
        const { amount: dropAmount } = action;
        if (
          !this._worldService.canStepOnTile(player) &&
          tile.item?.code !== item.code
        ) {
          return InventoryActions.dropItemFailedTileInvalid();
        } else if (!item || item.amount < dropAmount) {
          return InventoryActions.dropItemFailedNotEnough();
        }

        // Handle successful drop logic here
        return InventoryActions.dropItemSuccess({ item, amount: dropAmount });
      }),
    ),
  );

  private _dropItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.dropItemSuccess),
      withLatestFrom(this._store.select(getPlayerName)),
      switchMap(([{ item, amount }, playerName]) =>
        this._inventoryDbService.dropItem(item.code, amount).pipe(
          take(1),
          tap(() => {
            this._audioService.playSound('miscs', 'drop_leather', 'ogg');
            this._notificationService.addNotification(
              'validation',
              `-${amount} ${item.name}`,
              5000,
              item.img,
            );
          }),
          switchMap(() => [
            ChatActions.addChatLog({
              tab: ChatTab.ACTION,
              name: playerName,
              text: `a déposé au sol l'objet "${item.name}"`,
              kind: ChatLogKind.Malus,
            }),
            InventoryActions.removeItemInInventory({
              itemCode: item.code,
              amount,
            }),
          ]),
        ),
      ),
    ),
  );

  private _dropItemFailedNotEnough$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.dropItemFailedNotEnough),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas cet objet!",
          );
        }),
      ),
    { dispatch: false },
  );

  private _dropItemFailedTileInvalid$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.dropItemFailedTileInvalid),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Vous ne pouvez pas déposer ça ici!',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _inventoryDbService: InventoryDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
    private readonly _worldService: WorldGeneratorService,
  ) {}
}
