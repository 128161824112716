import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmount } from '@t12/inventory/store/selectors/inventory.selectors';
import { JobCodeToNameEnum } from '@t12/jobs/enums/job-code-to-name.enum';
import { RecipeDbService } from '@t12/jobs/services/recipe-db/recipe-db.service';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import {
  getPlayerJobWithCode,
  getWorkshop,
} from '@t12/jobs/store/selectors/job.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import {
  switchMap,
  map,
  take,
  tap,
  withLatestFrom,
  catchError,
  of,
} from 'rxjs';

@Injectable()
export class CraftRecipeEffects {
  private _craftRecipe$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.craftRecipe),
      switchMap(({ recipe }) =>
        this._store.select(getPlayerJobWithCode(recipe.jobCode)).pipe(
          take(1),
          withLatestFrom(
            this._store.select(getWorkshop),
            this._store.select(getFreeInventorySlotAmount(recipe.products[0])),
          ),
          map(([job, workshop, freeSlot]) => {
            if (!workshop) return JobActions.craftRecipeFailNoWorkshop();
            else if (!job)
              return JobActions.craftRecipeFailNoJob({
                jobCode: recipe.jobCode,
              });
            else if (freeSlot < recipe.products[0].amount)
              return JobActions.craftRecipeFailNotEnoughSpace();
            else return JobActions.craftRecipeSuccess({ recipe });
          }),
        ),
      ),
    ),
  );

  private _craftRecipeSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.craftRecipeSuccess),
      switchMap(({ recipe }) =>
        this._recipeDb.craftRecipe(recipe.code).pipe(
          take(1),
          switchMap((resultCraft) => {
            if (!resultCraft.product)
              return [JobActions.craftRecipeFailNoProduct()];

            const { amount, name, img } = resultCraft.product;
            this._notificationService.addNotification(
              'validation',
              `+${amount} ${name}`,
              5000,
              img,
            );

            const { playerJobXp, critical } = resultCraft;
            const actions = [
              InventoryActions.removeItemsInInventory({
                items: recipe.ingredients,
              }),
              InventoryActions.addItemInInventory({
                item: resultCraft.product,
                amount: resultCraft.product.amount,
              }),
              playerJobXp
                ? CharactersActions.incJobXP({
                    jobCode: recipe.jobCode,
                  })
                : null,
              critical ? JobActions.craftRecipeCritical() : null,
            ];
            return actions.filter((action) => action !== null);
          }),
          catchError(() => of(JobActions.craftRecipeFailNoProduct())),
        ),
      ),
    ),
  );

  private _craftRecipeCritical$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.craftRecipeCritical),
        tap(() => {
          this._notificationService.addNotification(
            'settings',
            'Fabrication critique réussie!',
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.craftRecipeFail),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur',
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailNoJob$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.craftRecipeFailNoJob),
        tap(({ jobCode }) => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas le métier requis! (${JobCodeToNameEnum[jobCode]})`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailNoWorkshop$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.craftRecipeFailNoWorkshop),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'êtes pas en face d'un atelier",
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailNotEnoughIngredients$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.craftRecipeFailNotEnoughIngredients),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez d'ingrédients",
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailNotEnoughSpace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.craftRecipeFailNotEnoughSpace),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez de place dans votre sac",
          );
        }),
      ),
    { dispatch: false },
  );

  private _craftRecipeFailNoProduct$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(JobActions.craftRecipeFailNoProduct),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Fabrication ratée...',
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _recipeDb: RecipeDbService,
    private readonly _store: Store,
  ) {}
}
