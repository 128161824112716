import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';

export const CONTAINER_FEATURE_KEY = 'container';

export interface ContainerState extends IContainer {
  displayedItems?: Item[];
  currentPage?: number;
  maxPage?: number;
}

export const initialContainerState: ContainerState = {
  id: undefined,
  name: undefined,
  img: undefined,
  items: [],
  displayedItems: [],
  kind: undefined,
  material: undefined,
  currentPage: 1,
  maxPage: 0,
  position: undefined,
};
