import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { isNPC } from '@t12/characters/constants/is-npc.constant';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayerPositionInFront,
  getCharacterInFrontOfPlayer,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { getHudDialog } from '@t12/overlay/store/selectors/hud-display.selectors';
import { PlayerActions } from '@t12/player/store/actions/player.actions';
import { getWorldTileAt } from '@t12/world/store/selector/world.selectors';
import {
  map,
  switchMap,
  filter,
  forkJoin,
  take,
  withLatestFrom,
  of,
} from 'rxjs';

@Injectable()
export class InteractionEffects {
  private _interaction$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PlayerActions.interaction),
      withLatestFrom(this._store.select(getPlayer)),
      filter(([_, player]) => player.health > 0),
      switchMap((action) =>
        this._store.select(getPlayerPositionInFront).pipe(
          take(1),
          switchMap((position) =>
            forkJoin({
              target: this._store
                .select(getCharacterInFrontOfPlayer)
                .pipe(take(1)),
              tile: this._store
                .select(getWorldTileAt(position.x, position.y))
                .pipe(take(1)),
              dialogOpened: this._store.select(getHudDialog).pipe(take(1)),
            }).pipe(
              map(({ target, tile, dialogOpened }) => ({
                target,
                position,
                tile,
                dialogOpened,
                action,
              })),
            ),
          ),
        ),
      ),
      map(({ target, position: { x, y }, tile, dialogOpened }) => {
        if (
          tile.item ||
          tile.container ||
          tile.harvestPoint ||
          tile.workshop ||
          tile.desc
        ) {
          return PlayerActions.interactTile({ position: { x, y } });
        } else if (target) return PlayerActions.interactCharacter();
        else if (dialogOpened) return DialogActions.continueConversation();
        else return null;
      }),
      filter((action) => !!action),
    ),
  );

  private _interactTile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PlayerActions.interactTile),
      switchMap(({ position: { x, y } }) =>
        this._store.select(getWorldTileAt(x, y)).pipe(
          take(1),
          map((tile) => {
            if (tile.item)
              return InventoryActions.pickItem({
                item: tile.item,
              });
            else if (tile.container)
              return ContainerActions.openContainer({
                container: tile.container,
              });
            else if (tile.harvestPoint)
              return JobActions.harvest({
                harvestPoint: tile.harvestPoint,
              });
            else if (tile.workshop)
              return JobActions.openWorkshop({
                workshop: tile.workshop,
              });
          }),
        ),
      ),
    ),
  );

  private _interactCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PlayerActions.interactCharacter),
      withLatestFrom(
        this._store.select(getCharacterInFrontOfPlayer),
        this._store.select(getHudDialog),
      ),
      map(([_, npc, dialogOpened]) => {
        if (!dialogOpened && isNPC(npc) && npc.state !== 'fight') {
          return DialogActions.startConversation({ npc: npc as INPC });
        } else if (dialogOpened) {
          return DialogActions.continueConversation();
        }
      }),
    ),
  );

  private _playerUseAnItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(PlayerActions.playerUseAnItem),
      switchMap(({ updateInfos }) => {
        const { id, health, mana } = updateInfos;
        const actions: Action[] = [];

        if (health && mana) {
          actions.push(
            CharactersActions.addHealthMana({
              id,
              kind: CharacterKind.PLAYER,
              health,
              mana,
            }),
          );
        } else if (health) {
          actions.push(
            CharactersActions.addHealth({
              id,
              kind: CharacterKind.PLAYER,
              health,
            }),
          );
        } else if (mana) {
          actions.push(
            CharactersActions.addMana({ id, kind: CharacterKind.PLAYER, mana }),
          );
        }

        return of(...actions);
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
