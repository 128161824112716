import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayerID,
  getCharacterById,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Character } from '@t12/common/characters/types/character.type';
import { HitKind } from '@t12/common/fight/types/hit-kind.type';
import { costManaMagicAttack } from '@t12/common/player/constants/cost-mana-magic-attack.constant';
import { ActiveTargetService } from '@t12/fight/services/active-target/active-target.service';
import { FightDbService } from '@t12/fight/services/fight-db/fight-db.service';
import { FightManagerService } from '@t12/fight/services/fight-manager/fight-manager.service';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { arePlayersInSameGroup } from '@t12/socials/store/selectors/socials.selectors';
import {
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  delay,
  filter,
  from,
} from 'rxjs';

@Injectable()
export class AttackCharacterEffects {
  private _localPlayerAttack$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.localPlayerAttack),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([{ attackKind }, id]) =>
        CharactersActions.attack({
          id,
          characterKind: CharacterKind.PLAYER,
          attackKind,
          emitDmg: true,
        }),
      ),
    ),
  );

  private _attack$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attack),
      switchMap((action) =>
        this._store
          .select(getCharacterById(action.id, action.characterKind))
          .pipe(
            take(1),
            map((character) => ({ action, character })),
          ),
      ),
      filter(({ character }) => character.health > 0),
      map(({ action: { id, attackKind, emitDmg }, character }) => {
        if (attackKind === 'physic') {
          return CharactersActions.attackPhysic({
            character,
            emitDmg,
          });
        } else {
          return CharactersActions.attackMagic({
            character,
            emitDmg,
          });
        }
      }),
    ),
  );

  private _attackPhysic$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackPhysic),
      map(({ character, emitDmg }) =>
        CharactersActions.attackFirstStep({
          character,
          attackKind: 'physic',
          emitDmg,
        }),
      ),
    ),
  );

  private _attackMagic$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackMagic),
      map(({ character, emitDmg }) => {
        const costMana = costManaMagicAttack(character.stats.int);
        if (character.mana < costMana)
          return CharactersActions.attackMagicFailedNotEnoughMana({
            id: character.id,
            costMana,
          });
        else
          return CharactersActions.attackFirstStep({
            character,
            attackKind: 'magic',
            emitDmg,
          });
      }),
    ),
  );

  private _attackMagicFailedNotEnoughMana$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersActions.attackMagicFailedNotEnoughMana),
        withLatestFrom(this._store.select(getPlayerID)),
        filter(([{ id }, playerId]) => id === playerId),
        tap(([{ costMana }]) => {
          this._notificationService.addNotification(
            'error',
            `Vous n'avez pas assez de mana! (${costMana} PM requis)`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _attackFirstStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackFirstStep),
      delay(300),
      map(({ character, attackKind, emitDmg }) =>
        CharactersActions.attackSecondStep({
          character,
          attackKind,
          emitDmg,
        }),
      ),
    ),
  );

  private _attackSecondStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackSecondStep),
      delay(100),
      map(({ character, attackKind }) =>
        CharactersActions.attackThirdStep({ character, attackKind }),
      ),
    ),
  );

  private _attackThirdStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackThirdStep),
      delay(276),
      map(({ character }) =>
        CharactersActions.idleStep({ id: character.id, kind: character.kind }),
      ),
    ),
  );

  private _attackTryToHit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.attackSecondStep),
      filter(({ emitDmg }) => !!emitDmg),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ attackKind }, playerId]) =>
        this._fightDbService.attackCharacter(playerId, attackKind).pipe(
          take(1),
          filter((attackResult) => !!attackResult.target),
          tap(
            ({
              attacker,
              target,
              hit: { damage, annexDamage, kind: hitKind },
            }) => {
              this._fightService.displayChatLogHit(
                attacker,
                target,
                [damage, annexDamage],
                attackKind,
                hitKind,
              );
            },
          ),
          switchMap(
            ({
              target: { id: targetId, kind, dead, code },
              hit: { damage },
              reward,
            }) =>
              [
                CharactersActions.attackDealDamage({
                  targetId,
                  targetKind: kind,
                  damage,
                }),
                reward && dead
                  ? CharactersActions.rewardFight({
                      target: { id: playerId, kind, code },
                      reward,
                    })
                  : null,
                dead
                  ? CharactersActions.characterIsDead({
                      id: targetId,
                      kind,
                    })
                  : null,
              ].filter((action) => !!action),
          ),
        ),
      ),
    ),
  );

  private _characterIsDead$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.characterIsDead),
      delay(1000),
      filter(({ kind }) => kind !== CharacterKind.PLAYER),
      map(({ id, kind }) =>
        CharactersActions.removeCharacterById({ id, kind }),
      ),
    ),
  );

  private _attackDealDamage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersActions.attackDealDamage),
        switchMap(({ targetId, targetKind }) =>
          this._store.select(getCharacterById(targetId, targetKind)).pipe(
            take(1),
            tap((target: Character) => {
              this._activeTargetService.setActiveTarget(target);
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  private _attackSound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          CharactersActions.attackHit,
          CharactersActions.attackTryToHitFailed,
        ),
        tap(({ attackKind, hit }) => {
          const soundType = attackKind === 'physic' ? 'hit' : 'spell';
          const randPhysic = (limit: number) =>
            Math.floor(Math.random() * limit);

          if (attackKind === 'physic') {
            const impactSoundMap: Partial<Record<HitKind, number>> = {
              block: 4,
              parry: 5,
              crit: 3,
              hit: 8,
            };

            if (hit in impactSoundMap) {
              const rand = randPhysic(impactSoundMap[hit]);
              this._audioService.playSound('impacts', `${hit}_${rand}`);
              return;
            }
          }

          const rand = randPhysic(attackKind === 'physic' ? 8 : 2);
          this._audioService.playSound('impacts', `${soundType}_${rand}`);
        }),
      ),
    { dispatch: false },
  );

  private _characterAttack$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.characterAttack),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ attackCharacterResult }, playerId]) => {
        const {
          target,
          attacker: { id, kind },
        } = attackCharacterResult;

        return this._store.select(getCharacterById(id, kind)).pipe(
          take(1),
          withLatestFrom(
            this._store.select(arePlayersInSameGroup(id, playerId)),
          ),
          switchMap(([attacker, areInSameGroup]) => {
            const { hit, reward } = attackCharacterResult;
            const actions: Action[] = [
              CharactersActions.attack({
                id: attacker.id,
                characterKind: attacker.kind,
                attackKind: hit.attackKind,
              }),
            ];

            if (target) {
              actions.push(
                CharactersActions.addHealth({
                  id: target.id,
                  kind: target.kind,
                  health: -hit.damage,
                }),
              );
            }

            if (
              target?.kind === CharacterKind.PLAYER &&
              target?.dead &&
              target?.id === playerId
            )
              actions.push(CharactersActions.playerDeath());

            if (
              target?.dead &&
              reward &&
              (attacker.id === playerId || areInSameGroup)
            )
              actions.push(
                CharactersActions.rewardFight({
                  target: {
                    id: target.id,
                    kind: target.kind,
                    code: target.code,
                  },
                  reward,
                }),
              );

            return from(actions);
          }),
        );
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _activeTargetService: ActiveTargetService,
    private readonly _audioService: AudioManagerService,
    private readonly _fightService: FightManagerService,
    private readonly _fightDbService: FightDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
