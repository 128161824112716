import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayerWorldCode } from '@t12/characters/store/selectors/characters.selectors';
import { ChatTab } from '@t12/chat/enums/chat-tab.enum';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { switchMap, take, mergeMap, withLatestFrom, combineLatest } from 'rxjs';
import { SocialsActions } from '../../actions/socials.actions';
import {
  isFriend,
  isPlayerInGroupByName,
} from '../../selectors/socials.selectors';

@Injectable()
export class PlayerLoggedEffects {
  private _playerLoggedIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.playerLoggedIn),
      withLatestFrom(this._store.select(getPlayerWorldCode)),
      switchMap(([{ player }, playerWorldCode]) =>
        combineLatest([
          this._store.select(isFriend(player.name)).pipe(take(1)),
          this._store.select(isPlayerInGroupByName(player.name)).pipe(take(1)),
        ]).pipe(
          mergeMap(([isFriend, isInGroup]) => {
            const actions = [];

            if (player.worldCode === playerWorldCode) {
              actions.push(CharactersActions.addPlayer({ id: player.id }));
            }

            if (isFriend) {
              actions.push(SocialsActions.friendLoggedIn({ player }));
            }

            if (isInGroup) {
              actions.push(
                SocialsActions.setPlayerGroupStatusOnline({
                  name: player.name,
                  online: true,
                }),
              );
            }

            return actions;
          }),
        ),
      ),
    ),
  );

  private _playerLoggedOut$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.playerLoggedOut),
      withLatestFrom(this._store.select(getPlayerWorldCode)),
      switchMap(([{ player }, playerWorldCode]) =>
        combineLatest([
          this._store.select(isFriend(player.name)).pipe(take(1)),
          this._store.select(isPlayerInGroupByName(player.name)).pipe(take(1)),
        ]).pipe(
          mergeMap(([isFriend, isInGroup]) => {
            const actions = [];

            if (player.worldCode === playerWorldCode) {
              actions.push(CharactersActions.removePlayer({ id: player.id }));
            }

            if (isFriend) {
              actions.push(SocialsActions.friendLoggedOut({ player }));
            }

            if (isInGroup) {
              actions.push(
                SocialsActions.setPlayerGroupStatusOnline({
                  name: player.name,
                  online: false,
                }),
              );
            }

            return actions;
          }),
        ),
      ),
    ),
  );

  private _friendLoggedIn$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.friendLoggedIn),
      switchMap(({ player }) => {
        return [
          ChatActions.addChatLog({
            tab: ChatTab.CHAT,
            name: '',
            text: `${player.name} vient de se connecter! (Lvl ${player.lvl})`,
            kind: ChatLogKind.Log,
          }),
          SocialsActions.setFriendOnlineStatus({
            name: player.name,
            online: true,
          }),
        ];
      }),
    ),
  );

  private _friendLoggedOut$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.friendLoggedOut),
      switchMap(({ player }) => {
        return [
          SocialsActions.setFriendOnlineStatus({
            name: player.name,
            online: false,
          }),
        ];
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
