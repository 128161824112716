import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayer,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { HotkeyState } from '@t12/key-commands/store/hotkey';
import { getHotkeys } from '@t12/key-commands/store/hotkey/selectors/hotkey.selectors';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { PlayerSocketService } from '@t12/player/services/player-socket/player-socket.service';
import { PlayerActions } from '@t12/player/store/actions/player.actions';
import { FullscreenManagerService } from '@t12/settings/services/fullscreen/fullscreen-manager.service';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import {
  filter,
  fromEvent,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { getEventsProgressStatus } from '../../events/store/selectors/events.selectors';
import { attackKey, moveKey } from '../constants/key-bindings.constant';
import { actionKey } from '../types/action-key.type';

@Injectable({
  providedIn: 'root',
})
export class KeyHandlerService {
  private _intervalTickInput: NodeJS.Timeout;

  constructor(
    private readonly _fullscreenService: FullscreenManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _playerSocketService: PlayerSocketService,
    private readonly _store: Store,
    private readonly _utils: UtilsService,
  ) {
    fromEvent(document, 'keydown')
      .pipe(
        throttleTime(138),
        filter(() => {
          const activeElement = document.activeElement;
          return (
            activeElement?.tagName !== 'INPUT' &&
            activeElement?.tagName !== 'TEXTAREA' &&
            !(
              activeElement instanceof HTMLInputElement &&
              activeElement.getAttribute('contenteditable') === 'true'
            )
          );
        }),
        map((event: KeyboardEvent) => ({
          key: event.key.toLowerCase(),
          event,
        })),
        withLatestFrom(
          this._store.select(getPlayerID),
          this._store.select(getEventsProgressStatus),
        ),
        filter(
          ([{ key }, _, eventStatus]) =>
            key === ' ' || eventStatus !== ProgressStatus.IN_PROGRESS,
        ),
        tap(([{ key, event }, playerId]) => {
          // In-game logic
          if (playerId) {
            if (key === 'i') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'inventory' }),
              );
            } else if (key === 'l') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'quests' }),
              );
              this._store.dispatch(
                HudDisplayActions.hideHud({ name: 'inventory' }),
              );
            } else if (key === 'j') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'jobs' }),
              );
              this._store.dispatch(
                HudDisplayActions.hideHud({ name: 'playerInfos' }),
              );
            } else if (key === 'c') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'playerInfos' }),
              );
              this._store.dispatch(HudDisplayActions.hideHud({ name: 'jobs' }));
            } else if (key === 'p') {
              this._store.dispatch(
                HudDisplayActions.toggleHud({ name: 'socials' }),
              );
            } else if (key === 'escape') {
              this._store.dispatch(DialogActions.endConversation());
              this._store.dispatch(HudDisplayActions.closeAllHud());
            } else if (key === 'y') {
              event.preventDefault();
              this._store.dispatch(HudDisplayActions.showHud({ name: 'chat' }));
            } else {
              const action = this.identifyAction(key);
              if (action) {
                const interval = action[0] === 'hotkey' ? 3000 : 276;

                if (!this._intervalTickInput) {
                  this.executeKey(action);

                  if (key !== ' ') {
                    this._intervalTickInput = setInterval(
                      () => this.executeKey(action),
                      interval,
                    );
                  }
                }
              }
            }
          }

          // Everywhere logic
          if (key === 'f11') {
            event.preventDefault();
            this._fullscreenService.switchFullscreen();
          }
        }),
      )
      .subscribe();

    fromEvent<KeyboardEvent>(document, 'keyup')
      .pipe(
        filter(() => {
          const activeElement = document.activeElement;
          return (
            activeElement?.tagName !== 'INPUT' &&
            activeElement?.tagName !== 'TEXTAREA' &&
            !(
              activeElement instanceof HTMLInputElement &&
              activeElement.getAttribute('contenteditable') === 'true'
            )
          );
        }),
        withLatestFrom(this._store.select(getPlayerID)),
        tap(([_, playerId]) => {
          if (!playerId) return;
          this._clearTimer();
        }),
        map(([event]) => event.key.toLowerCase()),
        map((key) => this.identifyAction(key)),
        filter((action) => action?.[0] === 'move'),
        switchMap(() => this._playerDbService.updatePlayer().pipe(take(1))),
      )
      .subscribe();
  }

  // Argument : Touche de clavier
  // Résultat : Retourne l'action selon la touche saisie et la direction ou le type d'attaque
  // TODO Convert getActionKey en un map pour accéder directement à l'action type
  public identifyAction(
    key: string,
  ): [actionKey, string | HotkeyState] | [actionKey] | null {
    const direction = this._getActionByKey(moveKey, key);
    const attack = this._getActionByKey(attackKey, key);
    const hotkeys = this._utils.getSelect(getHotkeys);
    const hotkey =
      hotkeys.length > 0
        ? hotkeys.find((hotkeyItem) => hotkeyItem.key === key)
        : undefined;

    if (direction) {
      return ['move', direction];
    } else if (attack) {
      return ['attack', attack];
    } else if (key === ' ') {
      return ['interaction'];
    } else if (hotkey) {
      return ['hotkey', hotkey];
    }
    return null;
  }

  // Argument : Touche de clavier
  // Résultat : Execute une action selon la touche de clavier renseignée
  public executeKey(action: [actionKey, any] | [actionKey]): void {
    const player: IPlayer = this._utils.getSelect(getPlayer);
    if (action[0] === 'move' && player.canMove) {
      this._store.dispatch(
        CharactersActions.localPlayerMove({ direction: action[1] }),
      );
    } else if (action[0] === 'attack' && player.canMove) {
      this._store.dispatch(
        CharactersActions.localPlayerAttack({
          attackKind: action[1],
          emitDmg: true,
        }),
      );
    } else if (action[0] === 'interaction') {
      this._store.dispatch(PlayerActions.interaction());
    } else if (action[0] === 'hotkey' && player.canMove) {
      this._store.dispatch(
        InventoryActions.useItem({ itemCode: action[1].entity.code }),
      );
    }
  }

  // Argument : Touche de clavier
  // Résultat : Retourne la direction de déplacement ou le type d'attaque selon la touche saisie
  private _getActionByKey(keyBinds: any, key: string): string {
    return Object.keys(keyBinds).find((keyObj) => {
      if (keyBinds[keyObj].includes(key)) return keyObj;
    });
  }

  // Arguments : ------
  // Résultat : Supprime l'intervalle de tick des  inputs
  private _clearTimer(): void {
    clearInterval(this._intervalTickInput);
    this._intervalTickInput = undefined;
  }
}
