import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import { isPlayer } from '@t12/characters/constants/is-player.constant';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayerID,
  getPlayerPosition,
  getCharacterById,
  isLocalPlayerById,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Warp } from '@t12/common/world/interfaces/warp.interface';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { getIsWorkshopOpen } from '@t12/jobs/store/selectors/job.selectors';
import { getAllHudDisplay } from '@t12/overlay/store/selectors/hud-display.selectors';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { PlayerSocketService } from '@t12/player/services/player-socket/player-socket.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { ShopActions } from '@t12/shop/store/actions/shop.actions';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import { getWorldTileAt } from '@t12/world/store/selector/world.selectors';
import {
  delay,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  of,
} from 'rxjs';

@Injectable()
export class MoveCharacterEffects {
  private _localPlayerMove$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.localPlayerMove),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([{ direction }, id]) =>
        CharactersActions.move({
          id,
          kind: CharacterKind.PLAYER,
          direction,
        }),
      ),
    ),
  );

  private _move$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.move),
      switchMap((action) =>
        this._store.select(getCharacterById(action.id, action.kind)).pipe(
          take(1),
          map((character) => ({ action, character })),
        ),
      ),
      withLatestFrom(
        this._store.select(getIsWorkshopOpen),
        this._store.select(getAllHudDisplay),
        this._store.select(getPlayerID),
      ),
      filter(([{ character }]) => !!character),
      switchMap(
        ([
          {
            action: { id, kind, direction, force },
            character,
          },
          workshopOpen,
          hudState,
          playerId,
        ]) => {
          const canMove =
            this._characterService.canMove(character, direction) || force;
          let actions: Action[] = [];
          if (canMove) {
            actions = [
              CharactersActions.moveStep({
                id,
                kind,
                direction: direction,
              }),
            ];

            if (isPlayer(character) && character.id === playerId) {
              this._playerSocketService.movePlayerAction(playerId, direction);

              if (hudState.dialog)
                actions.push(DialogActions.endConversation());

              if (hudState.shop) actions.push(ShopActions.closeShop());

              if (hudState.container)
                actions.push(ContainerActions.closeContainer());

              if (workshopOpen) actions.push(JobActions.closeWorkshop());

              if (hudState.bank) actions.push(BankActions.closeBank());
            }
          } else {
            this._playerSocketService.lookingPlayerAction(
              character.id,
              direction,
            );
          }
          return of(...actions);
        },
      ),
    ),
  );

  private _moveIdleStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.moveStep),
      delay(138),
      map(({ id, kind }) => CharactersActions.idleStep({ id, kind })),
    ),
  );

  private _moveStepSound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersActions.moveStep),
        tap((_) => {
          const rand = Math.floor(Math.random());
          this._audioService.playSound('footsteps', `grass_${rand}`);
        }),
      ),
    { dispatch: false },
  );

  private _teleportTo$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CharactersActions.idleStep),
        switchMap((action) =>
          this._store.select(isLocalPlayerById(action.id)).pipe(
            take(1),
            filter((isLocalPlayer) => isLocalPlayer),
            withLatestFrom(this._store.select(getPlayerPosition)),
            switchMap(([_, position]) =>
              this._store.select(getWorldTileAt(position.x, position.y)).pipe(
                take(1),
                tap((tile) => {
                  const warp: Warp = tile?.warp;
                  if (!warp) return;

                  this._playerDbService
                    .updatePlayer()
                    .pipe(take(1))
                    .subscribe(() => {
                      this._store.dispatch(WorldActions.teleportTo({}));
                    });
                }),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  private _resetWorld$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorld, WorldActions.teleportTo),
      tap(() => {
        this._timersService.stopAllTimersFight();
        this._timersService.stopAllTimersMove();
      }),
      map(() => CharactersActions.resetCharacters()),
    ),
  );

  private _teleportToSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.teleportToSuccess),
      withLatestFrom(this._store.select(getPlayerID)),
      tap(([{ world, warp }, playerId]) => {
        this._playerSocketService.playerLeaveWorldAction(playerId, world.code, {
          x: warp.position.x,
          y: warp.position.y,
        });
      }),
      switchMap(([{ world, warp }, id]) => [
        CharactersActions.setPositionXY({
          id,
          kind: CharacterKind.PLAYER,
          x: warp.position.x,
          y: warp.position.y,
        }),
        WorldActions.loadWorldSuccess({ world }),
      ]),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _characterService: CharacterManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _playerSocketService: PlayerSocketService,
    private readonly _store: Store,
    private readonly _timersService: TimersManagerService,
  ) {}
}
