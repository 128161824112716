import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttackCharacterDto } from '@t12/common/fight/dto/attack-character.dto';
import { AttackCharacterResult } from '@t12/common/fight/interfaces/attack-character.interface';
import { AttackKind } from '@t12/common/fight/types/attack-kind.type';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/auth/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FightDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
  ) {}

  public attackCharacter(
    playerId: number,
    attackKind: AttackKind,
  ): Observable<AttackCharacterResult> {
    const body: AttackCharacterDto = { playerId, attackKind };

    return this._http.post<AttackCharacterResult>(
      `${environment('player/attack').uri}`,
      body,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }
}
