import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { Bank } from '@t12/common/bank/interfaces/bank.interface';
import { DepositBank } from '@t12/common/bank/interfaces/deposit-bank.interface';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/auth/authentication.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BankDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
  ) {}

  public getBankPlayer(): Observable<Bank> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    return this._http.get(`${environment(`bank/player/${playerId}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<Bank>;
  }

  public depositToBank(
    playerId: number,
    item?: Item,
    gold?: number,
  ): Observable<void> {
    const depositDto: DepositBank = {
      playerId,
      item: item?.code ? { code: item.code, amount: item.amount } : undefined,
      amountGold: gold > 0 ? gold : undefined,
    };

    return this._http.post<void>(
      `${environment(`bank/add/deposit`).uri}`,
      depositDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  public depositFromBank(
    playerId: number,
    item?: Item,
    gold?: number,
  ): Observable<void> {
    const depositDto: DepositBank = {
      playerId,
      item: item?.code ? { code: item.code, amount: item.amount } : undefined,
      amountGold: gold > 0 ? gold : undefined,
    };
    return this._http.post<void>(
      `${environment(`bank/remove/deposit`).uri}`,
      depositDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }
}
