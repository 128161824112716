import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerName } from '@t12/characters/store/selectors/characters.selectors';
import { ChatTab } from '@t12/chat/enums/chat-tab.enum';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { InventoryDbService } from '@t12/inventory/services/inventory-db/inventory-db.service';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmount } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class InventoryPickEffects {
  private _pickItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.pickItem),
      throttleTime(200),
      filter(({ item }) => item.amount > 0),
      switchMap(({ item }) =>
        this._store.select(getFreeInventorySlotAmount(item)).pipe(
          take(1),
          map((freeSlotsAmount) => {
            const pickedAmount = Math.min(freeSlotsAmount, item.amount);

            if (freeSlotsAmount > 0 && freeSlotsAmount >= pickedAmount) {
              return InventoryActions.pickItemSuccess({
                item,
                amount: pickedAmount,
              });
            } else {
              return InventoryActions.pickItemFailed();
            }
          }),
        ),
      ),
    ),
  );

  private _pickItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.pickItemSuccess),
      withLatestFrom(this._store.select(getPlayerName)),
      switchMap(([{ item, amount }, name]) =>
        this._inventoryDbService.pickItem(item.code, amount).pipe(
          take(1),
          tap(() => {
            this._audioService.playSound('miscs', 'drop_leather', 'ogg');
            this._notificationService.addNotification(
              'validation',
              `+${amount} ${item.name}`,
              5000,
              item.img,
            );
          }),
          switchMap(() => [
            ChatActions.addChatLog({
              tab: ChatTab.ACTION,
              name,
              text: `a ramassé l'objet "${item.name}"`,
              kind: ChatLogKind.Bonus,
            }),
            InventoryActions.addItemInInventory({ item, amount }),
          ]),
        ),
      ),
    ),
  );

  private _pickItemFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.pickItemFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez de place dans votre sac",
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _inventoryDbService: InventoryDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
