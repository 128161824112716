import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getQuestsInfos } from '@t12/quest/store/selectors/quest.selectors';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { map, withLatestFrom } from 'rxjs';
import { CharactersActions } from '../../actions/characters.actions';

dayjs.locale('fr');

@Injectable()
export class CharactersEffects {
  private _setCharacters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.setCharacters),
      withLatestFrom(this._store.select(getQuestsInfos)),
      map(([{ characters }]) => CharactersActions.setCharactersSuccess()),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
