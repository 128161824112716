import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getCharacterByCode,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { DialogDbService } from '@t12/dialog/services/dialog-db/dialog-db.service';
import { DialogActions } from '@t12/dialog/store/actions/dialog.actions';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  concat,
  concatMap,
  delay,
  EMPTY,
  filter,
  finalize,
  from,
  map,
  of,
  switchMap,
  take,
  timer,
  withLatestFrom,
} from 'rxjs';
import { EventsActions } from '../actions/events.actions';
import {
  getEventsProgressStatus,
  getEventsStep,
} from '../selectors/events.selectors';

@Injectable()
export class EventsEffects {
  private _loadWorldSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorldSuccess),
      filter(({ world }) => !!world.eventSteps),
      map(({ world }) =>
        EventsActions.setEventSteps({ eventSteps: world.eventSteps }),
      ),
    ),
  );

  private _setEventSteps$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.setEventSteps),
      switchMap(() => [
        HudDisplayActions.hideHud({ name: 'barInfos' }),
        EventsActions.startEvent(),
      ]),
    ),
  );

  private _startEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEvent, EventsActions.nextEvent),
      withLatestFrom(this._store.select(getEventsStep)),
      map(([_, eventStep]) => {
        switch (eventStep?.type) {
          case 'weather':
            return EventsActions.startEventWeather({ event: eventStep });
          case 'dialog':
            return EventsActions.startEventDialog({ event: eventStep });
          case 'character':
            return EventsActions.startEventCharacter({ event: eventStep });
          case 'popup':
            return EventsActions.startEventPopup({ event: eventStep });
          default:
            return EventsActions.endEvent();
        }
      }),
    ),
  );

  private _startEventWeather$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventWeather),
      switchMap(({ event }) => {
        const actions = [];

        if (event.dayTime) {
          actions.push(WorldActions.setTime({ time: event.dayTime }));
        }
        if (event.weather) {
          actions.push(WorldActions.setWeather({ weather: event.weather }));
        }

        return from(actions);
      }),
      concatMap((action) =>
        concat(
          of(action), // Exécute chaque action
          of(EventsActions.nextEvent()).pipe(delay(4000)),
        ),
      ),
    ),
  );

  private _startEventDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventDialog),
      switchMap(({ event }) =>
        this._store.select(getCharacterByCode(event.characterCode)).pipe(
          take(1),
          switchMap((npc) =>
            this._dialogDbService.getDialog(event.dialogCode).pipe(
              take(1),
              map((textsDialog) =>
                DialogActions.startNarration({
                  textsDialog,
                  npc,
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  private _startEventCharacter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventCharacter),
      switchMap(({ event }) =>
        this._store.select(getCharacterByCode(event.characterCode)).pipe(
          take(1),
          switchMap((character) => {
            const actionsToExecute = event.actions.flatMap((action) => action);

            return from(actionsToExecute).pipe(
              concatMap((action) => {
                if ('attackKind' in action) {
                  const { attackKind } = action;
                  return timer(276).pipe(
                    map(() =>
                      CharactersActions.attack({
                        id: character.id,
                        characterKind: character.kind,
                        attackKind,
                      }),
                    ),
                  );
                } else if ('looking' in action) {
                  const { looking } = action;
                  return timer(276).pipe(
                    map(() =>
                      CharactersActions.setLooking({
                        id: character.id,
                        kind: character.kind,
                        looking,
                      }),
                    ),
                  );
                } else if ('direction' in action) {
                  const { direction } = action;
                  return timer(276).pipe(
                    map(() =>
                      CharactersActions.move({
                        id: character.id,
                        kind: character.kind,
                        direction,
                        force: true,
                      }),
                    ),
                  );
                }
                return EMPTY;
              }),
              finalize(() => this._store.dispatch(EventsActions.nextEvent())),
            );
          }),
        ),
      ),
    ),
  );

  private _startEventPopup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEventPopup),
      map(({ event }) => HudDisplayActions.showHud({ name: event.hudCode })),
    ),
  );

  private _freezePlayerStartEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.startEvent),
      withLatestFrom(this._store.select(getPlayerID)),
      map(([_, id]) =>
        CharactersActions.setCanMove({
          id,
          kind: CharacterKind.PLAYER,
          canMove: false,
        }),
      ),
    ),
  );

  private _endConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.endConversation),
      withLatestFrom(this._store.select(getEventsProgressStatus)),
      switchMap(([_, progressStatus]) => {
        return progressStatus === ProgressStatus.IN_PROGRESS
          ? of(EventsActions.nextEvent())
          : EMPTY;
      }),
    ),
  );

  private _unfreezePlayerStartEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EventsActions.endEvent),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, id]) => [
        CharactersActions.setCanMove({
          id,
          kind: CharacterKind.PLAYER,
          canMove: true,
        }),
        HudDisplayActions.showHud({ name: 'barInfos' }),
      ]),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _dialogDbService: DialogDbService,
    private readonly _store: Store,
  ) {}
}
