import { Group } from '@t12/common/socials/interfaces/group.interface';
import { PlayerFriendRequest } from '@t12/common/socials/interfaces/player-friend-request.interface';
import { PlayerFriend } from '@t12/common/socials/interfaces/player-friend.interface';
import { PlayerGroupRequest } from '@t12/common/socials/interfaces/player-group-request.interface';
import { PlayerGuild } from '@t12/common/socials/interfaces/player-guild.interface';
import { SocialTab } from '../enums/social-tab.enum';

export const SOCIALS_FEATURE_KEY = 'socials';

export interface SocialsState {
  friends?: {
    list: PlayerFriend[];
    displayedFriends: PlayerFriend[];
    currentPage: number;
    maxPage: number;
  };
  friendRequests?: {
    list: PlayerFriendRequest[];
    displayedFriends: PlayerFriendRequest[];
  };
  activeTab?: SocialTab;
  groupRequests?: PlayerGroupRequest[];
  group?: Group;
  guild?: PlayerGuild;
}

export const initialSocialsState: SocialsState = {
  friendRequests: {
    list: [],
    displayedFriends: [],
  },
  friends: {
    list: [],
    displayedFriends: [],
    currentPage: 1,
    maxPage: 1,
  },
  groupRequests: [],
  group: {
    leaderName: '',
    playersInfos: [],
  },
  activeTab: SocialTab.Friend,
};
