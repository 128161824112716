import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { ChatTab } from '@t12/chat/enums/chat-tab.enum';
import { ChatManagerService } from '@t12/chat/services/chat-manager.service';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { CharacterMoveActionSocket } from '@t12/common/characters/constants/character-move-action-socket.interface';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { SocketPickItem } from '@t12/common/container/interfaces/socket-pick-item.interface';
import { SocketRemoveContainer } from '@t12/common/container/interfaces/socket-remove-container.interface';
import { AttackCharacterResult } from '@t12/common/fight/interfaces/attack-character.interface';
import { CharacterLookingActionSocket } from '@t12/common/player/constants/character-looking-action-socket.interface';
import { PlayerActionLeaveWorld } from '@t12/common/player/constants/player-action-leave-world-socket.interface';
import { PlayerLookingActionSocket } from '@t12/common/player/constants/player-looking-action-socket.interface';
import { PlayerMoveActionSocket } from '@t12/common/player/constants/player-move-action-socket.interface';
import { PlayerUpdateInfosActionSocket } from '@t12/common/player/constants/player-update-infos-action-socket.interface';
import { GroupLeaderModified } from '@t12/common/socials/interfaces/group-leader-modified.interface';
import { PlayerFriendRequest } from '@t12/common/socials/interfaces/player-friend-request.interface';
import { PlayerFriend } from '@t12/common/socials/interfaces/player-friend.interface';
import { PlayerGroupInfos } from '@t12/common/socials/interfaces/player-group-infos.interface';
import { PlayerLogged } from '@t12/common/socials/interfaces/player-logged.interface';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import { environment } from '@t12/environment';
import { PlayerActions } from '@t12/player/store/actions/player.actions';
import { SocialsActions } from '@t12/socials/store/actions/socials.actions';
import { isFriend } from '@t12/socials/store/selectors/socials.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly _chatService: ChatManagerService,
    private readonly _store: Store,
    private readonly _utilsService: UtilsService,
  ) {}

  // Initialiser la connexion socket avec un playerId dynamique
  public initSocket(playerId: number): void {
    const config: SocketIoConfig = {
      url: environment('').ws,
      options: {
        query: {
          playerId: playerId.toString(),
        },
      },
    };

    this.socket = new Socket(config);
    const id = this._utilsService.getSelect(getPlayerID);

    this._store.dispatch(
      CharactersActions.setCanMove({
        id,
        kind: CharacterKind.PLAYER,
        canMove: true,
      }),
    );
    this._listenForChatActions();
    this._listenForPlayerMoveActions();
    this._listenForCharacterMoveActions();
    this._listenForCharacterLookingActions();
    this._listenForPlayerLookingActions();
    this._listenForCharacterAttackActions();
    this._listenForPlayerUpdateInfosActions();
    this._listenForPlayerLvlUpActions();
    this._listenForPlayerLoggedOutActions();
    this._listenForPlayerLeftWorldInActions();
    this._listenForPlayerLoggedInActions();
    this._listenForPlayerEnterWorldInActions();
    this._listenForPlayerSocialsFriendsActions();
    this._listenForPlayerSocialsGroupActions();
    this._listenForSocialsFriendAcceptActions();
    this._listenForSocialsGroupAcceptActions();
    this._listenForSocialsGroupChangeLeadActions();
    this._listenForSocialsGroupPlayerLeftActions();
    this._listenForSocialsGroupPlayerKickActions();
    this._listenForNpcRespawnActions();
    this._listenForContainerPickPlayerActions();
  }

  public emit(event: string, data: any): void {
    if (!this.socket) return;

    this.socket.emit(event, data);
  }

  public fromEvent<T>(event: string) {
    if (!this.socket) return;

    return this.socket.fromEvent<T>(event);
  }

  public disconnect(): void {
    this.socket.disconnect();
    this.unsubscribeAll();
  }

  private _listenForChatActions() {
    const chatSubscription = this.fromEvent<ChatLog>('chat')
      .pipe()
      .subscribe(({ name, scope, text, kind, time }) => {
        const isPlayerFriend = this._utilsService.getSelect(isFriend(name));

        if (scope === ChatLogScope.Pm && !isPlayerFriend) return;
        const chatLog = this._chatService.initChatLog(
          name,
          scope,
          text,
          kind,
          time,
        );

        this._store.dispatch(
          ChatActions.receiveChatLog({
            tab: ChatTab.CHAT,
            chatLog,
          }),
        );
      });

    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerMoveActions() {
    const playerSubscription = this.fromEvent('player-move').subscribe(
      ({ id, direction }: PlayerMoveActionSocket) => {
        this._store.dispatch(
          CharactersActions.move({
            id,
            kind: CharacterKind.PLAYER,
            direction,
            force: true,
          }),
        );
      },
    );
    this.subscriptions.push(playerSubscription);
  }

  private _listenForCharacterMoveActions() {
    const playerSubscription = this.fromEvent('character-move').subscribe(
      ({ id, direction, kind }: CharacterMoveActionSocket) => {
        this._store.dispatch(
          CharactersActions.move({
            id,
            kind,
            direction,
          }),
        );
      },
    );
    this.subscriptions.push(playerSubscription);
  }
  private _listenForCharacterLookingActions() {
    const playerSubscription = this.fromEvent('character-looking').subscribe(
      ({ id, looking, kind }: CharacterLookingActionSocket) => {
        this._store.dispatch(
          CharactersActions.setLooking({
            id,
            kind,
            looking,
          }),
        );
      },
    );
    this.subscriptions.push(playerSubscription);
  }

  private _listenForPlayerLookingActions() {
    const playerSubscription = this.fromEvent('player-looking').subscribe(
      ({ id, looking }: PlayerLookingActionSocket) => {
        this._store.dispatch(
          CharactersActions.setLooking({
            id,
            kind: CharacterKind.PLAYER,
            looking,
          }),
        );
      },
    );
    this.subscriptions.push(playerSubscription);
  }

  private _listenForCharacterAttackActions() {
    const chatSubscription = this.fromEvent('character-attack').subscribe(
      (attackCharacterResult: AttackCharacterResult) => {
        this._store.dispatch(
          CharactersActions.characterAttack({ attackCharacterResult }),
        );
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerLoggedOutActions() {
    const chatSubscription = this.fromEvent('player-logged-out').subscribe(
      (player: PlayerLogged) => {
        this._store.dispatch(SocialsActions.playerLoggedOut({ player }));
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerLeftWorldInActions() {
    const chatSubscription = this.fromEvent('player-leave-world').subscribe(
      ({ id }: PlayerActionLeaveWorld) => {
        this._store.dispatch(CharactersActions.removePlayer({ id }));
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerLoggedInActions() {
    const chatSubscription = this.fromEvent('player-logged-in').subscribe(
      (player: PlayerLogged) => {
        this._store.dispatch(SocialsActions.playerLoggedIn({ player }));
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  private _listenForPlayerEnterWorldInActions() {
    const chatSubscription = this.fromEvent('player-enter-world').subscribe(
      ({ id }: PlayerActionLeaveWorld) => {
        this._store.dispatch(CharactersActions.addPlayer({ id }));
      },
    );
    this.subscriptions.push(chatSubscription);
  }

  public unsubscribeAll() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private _listenForPlayerSocialsFriendsActions() {
    const socialSubscription = this.fromEvent(
      'player-friend-request',
    ).subscribe((friendRequest: PlayerFriendRequest) => {
      this._store.dispatch(SocialsActions.addFriendRequest({ friendRequest }));
    });
    this.subscriptions.push(socialSubscription);
  }

  private _listenForPlayerSocialsGroupActions() {
    const socialSubscription = this.fromEvent('player-group-request').subscribe(
      (groupRequest: PlayerFriendRequest) => {
        this._store.dispatch(SocialsActions.addGroupRequest({ groupRequest }));
      },
    );
    this.subscriptions.push(socialSubscription);
  }

  private _listenForSocialsFriendAcceptActions() {
    const socialSubscription = this.fromEvent(
      'player-friend-request-accepted',
    ).subscribe((playerFriend: PlayerFriend) => {
      this._store.dispatch(SocialsActions.addFriend({ playerFriend }));
    });
    this.subscriptions.push(socialSubscription);
  }

  private _listenForSocialsGroupAcceptActions() {
    const socialSubscription = this.fromEvent(
      'player-group-request-accepted',
    ).subscribe((player: PlayerGroupInfos) => {
      this._store.dispatch(SocialsActions.playerJoinGroup({ player }));
    });
    this.subscriptions.push(socialSubscription);
  }

  private _listenForSocialsGroupChangeLeadActions() {
    const socialSubscription = this.fromEvent(
      'player-group-leader-changed',
    ).subscribe((groupLeaderModified: GroupLeaderModified) => {
      this._store.dispatch(
        SocialsActions.setGroupLeaderSuccess({
          leader: { name: groupLeaderModified.leaderName },
        }),
      );
    });
    this.subscriptions.push(socialSubscription);
  }

  private _listenForSocialsGroupPlayerLeftActions() {
    const socialSubscription = this.fromEvent('player-group-leave').subscribe(
      (name: string) => {
        this._store.dispatch(
          SocialsActions.playerLeftGroup({
            name,
          }),
        );
      },
    );
    this.subscriptions.push(socialSubscription);
  }

  private _listenForSocialsGroupPlayerKickActions() {
    const socialSubscription = this.fromEvent('player-group-kick').subscribe(
      () => {
        this._store.dispatch(SocialsActions.kickFromGroup());
      },
    );
    this.subscriptions.push(socialSubscription);
  }

  private _listenForNpcRespawnActions() {
    const socialSubscription = this.fromEvent('respawn-character').subscribe(
      (character: INPC | IMonster) => {
        this._store.dispatch(CharactersActions.addCharacter({ character }));
      },
    );
    this.subscriptions.push(socialSubscription);
  }

  private _listenForContainerPickPlayerActions() {
    const socialSubscription = this.fromEvent(
      'player-container-pick',
    ).subscribe((socketPickItem: SocketPickItem) => {
      this._store.dispatch(
        ContainerActions.playerPickAnItem({ ...socketPickItem }),
      );
    });
    this.subscriptions.push(socialSubscription);

    const containerEmpty = this.fromEvent('player-container-remove').subscribe(
      ({ id, ...socketRemoveContainer }: SocketRemoveContainer) => {
        this._store.dispatch(
          WorldActions.removeEntity({ ...socketRemoveContainer }),
        );
      },
    );
    this.subscriptions.push(containerEmpty);
  }

  private _listenForPlayerUpdateInfosActions() {
    const socialSubscription = this.fromEvent('player-update-infos').subscribe(
      (updateInfos: PlayerUpdateInfosActionSocket) => {
        this._store.dispatch(PlayerActions.playerUseAnItem({ updateInfos }));
      },
    );
    this.subscriptions.push(socialSubscription);
  }

  private _listenForPlayerLvlUpActions() {
    const socialSubscription = this.fromEvent('player-level-up').subscribe(
      (playerId: number) => {
        this._store.dispatch(
          CharactersActions.levelUp({
            id: playerId,
            kind: CharacterKind.PLAYER,
          }),
        );
      },
    );
    this.subscriptions.push(socialSubscription);
  }
}
