import { createReducer, on } from '@ngrx/store';
import { directionMap } from '@t12/characters/constants/direction-map.constant';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { getLeftCharacter } from '@t12/common/characters/constants/get-left-character.constant';
import { getTopCharacter } from '@t12/common/characters/constants/get-top-character.constant';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { SpriteAnimationsX } from '@t12/common/characters/enums/sprite-animations-x.enum';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { EquipmentSlotIndex } from '@t12/common/equipments/enums/equipment-slot-index.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { costManaMagicAttack } from '@t12/common/player/constants/cost-mana-magic-attack.constant';
import { lookingSpriteY } from '@t12/common/player/constants/looking-sprite-y.constant';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { calculateXpForNextLevel } from '@t12/common/player/constants/next-level-xp.constant';
import { getKindJobByCode } from '@t12/jobs/constants/get-job-kind-by-code.constant';
import { defaultDeathPlayerState } from '@t12/player/constants/default-death-player-state.constant';
import { CharactersActions } from '../actions/characters.actions';
import { CharacterState, initialCharactersState } from '../index';

export const CharactersReducer = createReducer(
  initialCharactersState,

  on(CharactersActions.loadPlayerSuccess, (_, { player }) => {
    const { inventory, ...playerWithoutInventory } = player;

    const playerCharacter: IPlayer = {
      ...playerWithoutInventory,
      canMove: false,
      top: getTopCharacter(player.position.y),
      left: getLeftCharacter(player.position.x),
      spriteX: SpriteAnimationsX.IDLE_STEP_X,
      spriteY: lookingSpriteY[player.looking],
      firstStep: false,
      kind: CharacterKind.PLAYER,
    };

    return [playerCharacter];
  }),

  on(
    CharactersActions.addPlayerSuccess,
    (charactersState: CharacterState, { player }) => {
      return [
        ...charactersState,
        { ...player, canMove: true, dead: false },
      ] as CharacterState;
    },
  ),

  on(
    CharactersActions.removePlayer,
    (charactersState: CharacterState, { id }) => {
      return charactersState.filter(
        (character) => isNPCOrMonster(character) || character.id !== id,
      ) as CharacterState;
    },
  ),

  on(
    CharactersActions.addCharacter,
    (charactersState: CharacterState, { character }) => {
      return [
        ...charactersState,
        {
          ...character,
          canMove: true,
          dead: false,
          top: getTopCharacter(character.position.y),
          left: getLeftCharacter(character.position.x),
          spriteX: SpriteAnimationsX.IDLE_STEP_X,
          spriteY: lookingSpriteY[character.looking],
          firstStep: false,
          kind: character.kind,
        },
      ] as CharacterState;
    },
  ),

  on(
    CharactersActions.removeCharacterById,
    (charactersState: CharacterState, { id, kind }) => {
      return charactersState.filter(
        (character) => !(character.id === id && character.kind === kind),
      ) as CharacterState;
    },
  ),

  on(
    CharactersActions.setCharacters,
    (charactersState: CharacterState, { characters }) => {
      const player = charactersState[0];

      return [player, ...characters] as CharacterState;
    },
  ),

  on(CharactersActions.resetCharacters, (charactersState: CharacterState) => [
    charactersState[0],
  ]),

  on(
    CharactersActions.move,
    (charactersState: CharacterState, { id, kind, direction }) =>
      charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? {
              ...character,
              looking: direction,
              spriteY: lookingSpriteY[direction],
            }
          : character,
      ),
  ),

  on(
    CharactersActions.moveStep,
    (charactersState: CharacterState, { id, kind, direction }) => {
      const { left, top, x, y } = directionMap[direction];

      return charactersState.map((character: Character) =>
        character.id === id && character.kind === kind
          ? {
              ...character,
              left: character.left + left,
              top: character.top + top,
              position: {
                x: character.position.x + x,
                y: character.position.y + y,
              },
              spriteX: character.firstStep
                ? SpriteAnimationsX.FIRST_STEP_X
                : SpriteAnimationsX.SECOND_STEP_X,
              canMove: false,
              firstStep: !character.firstStep,
            }
          : character,
      ) as CharacterState;
    },
  ),

  on(
    CharactersActions.idleStep,
    (charactersState: CharacterState, { id, kind }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        canMove: character.health > 0,
        spriteX: SpriteAnimationsX.IDLE_STEP_X,
      });

      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.attack,
    (charactersState: CharacterState, { id, characterKind }) =>
      charactersState.map((character) =>
        character.id === id && character.kind === characterKind
          ? {
              ...character,
            }
          : character,
      ),
  ),

  on(
    CharactersActions.attackFirstStep,
    (charactersState: CharacterState, { character, attackKind }) =>
      charactersState.map((characterF) =>
        characterF.id === character.id && characterF.kind === character.kind
          ? {
              ...characterF,
              spriteX:
                attackKind === 'physic'
                  ? SpriteAnimationsX.FIRST_PHYSICAL_ATTACK_X
                  : SpriteAnimationsX.FIRST_MAGICAL_ATTACK_X,
              canMove: false,
              mana:
                attackKind === 'magic'
                  ? character.mana - costManaMagicAttack(character.stats.int)
                  : character.mana,
            }
          : characterF,
      ),
  ),

  on(
    CharactersActions.attackSecondStep,
    (charactersState: CharacterState, { character, attackKind }) =>
      charactersState.map((characterF) =>
        characterF.id === character.id && characterF.kind === character.kind
          ? {
              ...characterF,
              spriteX:
                attackKind === 'physic'
                  ? SpriteAnimationsX.SECOND_PHYSICAL_ATTACK_X
                  : SpriteAnimationsX.SECOND_MAGICAL_ATTACK_X,
            }
          : characterF,
      ),
  ),

  on(
    CharactersActions.attackThirdStep,
    (charactersState: CharacterState, { character, attackKind }) =>
      charactersState.map((characterF) =>
        characterF.id === character.id && characterF.kind === character.kind
          ? {
              ...characterF,
              spriteX:
                attackKind === 'physic'
                  ? SpriteAnimationsX.LAST_PHYSICAL_ATTACK_X
                  : SpriteAnimationsX.LAST_MAGICAL_ATTACK_X,
            }
          : characterF,
      ),
  ),

  on(
    CharactersActions.attackDealDamage,
    (charactersState: CharacterState, { targetId, targetKind, damage }) =>
      charactersState.map((character) =>
        character.id === targetId && targetKind === character.kind
          ? {
              ...character,
              health:
                character.health - damage > 0 ? character.health - damage : 0,
            }
          : character,
      ),
  ),

  on(
    CharactersActions.characterIsDead,
    (charactersState: CharacterState, { id, kind }) =>
      charactersState.map((character) =>
        character.id === id && kind === character.kind
          ? {
              ...character,
              health: 0,
            }
          : character,
      ),
  ),

  on(
    CharactersActions.npcDeath,
    (charactersState: CharacterState, { target }) =>
      charactersState.map((character) =>
        character.id === target.id
          ? {
              ...character,
              dead: true,
            }
          : character,
      ),
  ),

  on(
    CharactersActions.npcDeathSuccess,
    (charactersState: CharacterState, { target }) =>
      charactersState
        .filter((character) => character.id !== target.id)
        .map((character) => character) as CharacterState,
  ),

  on(
    CharactersActions.setPositionXY,
    (charactersState: CharacterState, { id, kind, x, y }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        position: { x, y },
        top: getTopCharacter(y),
        left: getLeftCharacter(x),
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setLooking,
    (charactersState: CharacterState, { id, kind, looking }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        spriteY: lookingSpriteY[looking],
        looking,
      });

      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setFaceToPlayer,
    (charactersState: CharacterState, { id, kind }) => {
      const updateCharacter = (character: Character) => {
        const { position: position1 } = charactersState[0];
        const { position: position2 } = character;

        const directions = {
          up: position1.y < position2.y,
          down: position1.y > position2.y,
          left: position1.x < position2.x,
          right: position1.x > position2.x,
        };

        const looking = Object.keys(directions).find(
          (key) => directions[key],
        ) as Looking;

        if (!position1 || !position2) return;

        return {
          ...character,
          spriteY: lookingSpriteY[looking],
          looking,
        };
      };

      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.setCanMove,
    (charactersState: CharacterState, { id, kind, canMove }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        canMove,
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addHealth,
    (charactersState: CharacterState, { id, kind, health }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        health:
          character.health + health < 0
            ? 0
            : Math.min(
                character.health + health,
                maxHealth(character.stats.con),
              ),
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addMana,
    (charactersState: CharacterState, { id, kind, mana }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        mana:
          character.mana + mana < 0
            ? 0
            : Math.min(character.mana + mana, maxMana(character.stats.int)),
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addHealthMana,
    (charactersState: CharacterState, { id, kind, health, mana }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        health:
          character.health + health < 0
            ? 0
            : Math.min(
                character.health + health,
                maxHealth(character.stats.con),
              ),
        mana:
          character.mana + mana < 0
            ? 0
            : Math.min(character.mana + mana, maxMana(character.stats.int)),
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(CharactersActions.playerDeath, (charactersState: CharacterState) => {
    return charactersState.map((character, index) =>
      index === 0 ? ({ ...character, dead: true } as IPlayer) : character,
    );
  }),

  on(
    CharactersActions.applyDeathSanction,
    (charactersState: CharacterState, { id, worldPosition }) => {
      const { worldCode, position } = worldPosition;

      return charactersState.map((character) =>
        character.id === id && character.kind === CharacterKind.PLAYER
          ? ({
              ...character,
              ...defaultDeathPlayerState(character, worldCode, position),
            } as IPlayer)
          : character,
      );
    },
  ),

  on(
    CharactersActions.localLevelUp,
    (charactersState: CharacterState, { id, kind, xp }) => {
      const updateCharacter = ({
        lvl,
        stats,
        xp: currentXp,
        ...character
      }: IPlayer) => ({
        ...character,
        lvl: lvl + 1,
        stats: {
          ...stats,
          for: stats.for + 1,
          con: stats.con + 1,
          dex: stats.dex + 1,
          int: stats.int + 1,
          sag: stats.sag + 1,
        },
        health: maxHealth(stats.con + 1),
        mana: maxMana(stats.int + 1),
        xp: (currentXp + xp) % calculateXpForNextLevel(lvl),
      });

      return charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );
    },
  ),

  on(
    CharactersActions.levelUp,
    (charactersState: CharacterState, { id, kind }) => {
      const updateCharacter = ({ lvl, stats, ...character }: IPlayer) => ({
        ...character,
        lvl: lvl + 1,
        stats: {
          ...stats,
          for: stats.for + 1,
          con: stats.con + 1,
          dex: stats.dex + 1,
          int: stats.int + 1,
          sag: stats.sag + 1,
        },
        health: maxHealth(stats.con + 1),
        mana: maxMana(stats.int + 1),
      });

      return charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );
    },
  ),

  on(
    CharactersActions.addXp,
    (charactersState: CharacterState, { id, kind, xp }) =>
      charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? {
              ...character,
              xp: (character.xp + xp) % calculateXpForNextLevel(character.lvl),
            }
          : character,
      ),
  ),

  on(
    CharactersActions.setState,
    (charactersState: CharacterState, { id, kind, state }) => {
      const updateCharacter = (character: Character) => ({
        ...character,
        state,
      });
      const updatedState = charactersState.map((character) =>
        character.id === id && character.kind === kind
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(CharactersActions.addGold, (charactersState: CharacterState, { gold }) => {
    const updatedState = charactersState.map((character, index) =>
      index === 0 ? { ...character, gold: character.gold + gold } : character,
    );

    return [...updatedState];
  }),

  on(
    CharactersActions.equipItem,
    (charactersState: CharacterState, { item, oldEquipment }) => {
      const { stats } = item;
      const { stats: oldStats = {} } = oldEquipment || {}; // Assure que oldStats est défini

      return charactersState.map((character, index) => {
        if (index === 0) {
          const player = character as IPlayer;
          const equipments = [...player.equipments];

          equipments[EquipmentSlotIndex[item.slot]] = { ...item, amount: 1 };

          return {
            ...player,
            equipments,
            health:
              (stats?.con ? player.health + stats.con * 4 : player.health) -
              (oldStats?.con ? oldStats.con * 4 : 0),
            mana:
              (stats?.int ? player.mana + stats.int * 3 : player.mana) -
              (oldStats?.int ? oldStats.int * 3 : 0),
            stats: {
              ...player.stats,
              for: player.stats.for + (stats?.for || 0) - (oldStats?.for || 0),
              con: player.stats.con + (stats?.con || 0) - (oldStats?.con || 0),
              dex: player.stats.dex + (stats?.dex || 0) - (oldStats?.dex || 0),
              int: player.stats.int + (stats?.int || 0) - (oldStats?.int || 0),
              sag: player.stats.sag + (stats?.sag || 0) - (oldStats?.sag || 0),
              cha: player.stats.cha + (stats?.cha || 0) - (oldStats?.cha || 0),
            },
          };
        }
        return character;
      });
    },
  ),

  on(
    CharactersActions.unequipItem,
    (charactersState: CharacterState, { item }) => {
      const { stats } = item;

      return charactersState.map((character, index) => {
        if (index === 0) {
          const player = character as IPlayer;
          const equipments = [...player.equipments];

          equipments[EquipmentSlotIndex[item.slot]] = { amount: 0 } as Item;

          return {
            ...player,
            equipments,
            health: stats?.con
              ? Math.max(player.health - stats.con * 4, 1)
              : player.health,
            mana: stats?.int
              ? Math.max(player.mana - stats.int * 3, 0)
              : player.mana,
            stats: {
              ...player.stats,
              for: player.stats.for - (stats?.for || 0),
              con: player.stats.con - (stats?.con || 0),
              dex: player.stats.dex - (stats?.dex || 0),
              int: player.stats.int - (stats?.int || 0),
              sag: player.stats.sag - (stats?.sag || 0),
              cha: player.stats.cha - (stats?.cha || 0),
            },
          };
        }

        return character;
      });
    },
  ),

  on(
    CharactersActions.setQuestIcon,
    (charactersState: CharacterState, { codeCharacter, questIcon }) => {
      const updateCharacter = (character) => ({
        ...character,
        questIcon,
      });
      const updatedState = charactersState.map((character) =>
        character.code === codeCharacter
          ? updateCharacter(character)
          : character,
      );

      return [...updatedState];
    },
  ),

  on(
    CharactersActions.addNewJob,
    (charactersState: CharacterState, { job }) => {
      return charactersState.map((character, index) => {
        if (index === 0 && 'jobs' in character) {
          const player = character as IPlayer;

          return {
            ...player,
            jobs: {
              ...player.jobs,
              [job.kind]: [...(player.jobs[job.kind] || []), job],
            },
          };
        }

        return character;
      });
    },
  ),

  on(
    CharactersActions.removeJob,
    (charactersState: CharacterState, { job }) => {
      return charactersState.map((character, index) => {
        if (index === 0 && 'jobs' in character) {
          const player = character as IPlayer;

          return {
            ...player,
            jobs: {
              ...player.jobs,
              [job.kind]: player.jobs[job.kind].filter(
                (jobItem: PlayerJob) => jobItem.code !== job.code,
              ),
            },
          };
        }
        return character;
      });
    },
  ),

  on(CharactersActions.setJobs, (charactersState: CharacterState, { jobs }) => {
    return charactersState.map((character, index) =>
      index === 0 && 'jobs' in character ? { ...character, jobs } : character,
    );
  }),

  on(
    CharactersActions.incJobXP,
    (charactersState: CharacterState, { jobCode }) => {
      return charactersState.map((character, index) =>
        index === 0 && 'jobs' in character
          ? {
              ...character,
              jobs: {
                ...character.jobs,
                [getKindJobByCode(jobCode)]: character.jobs[
                  getKindJobByCode(jobCode)
                ].map((job) =>
                  job.code === jobCode ? { ...job, xp: job.xp + 1 } : job,
                ),
              },
            }
          : character,
      );
    },
  ),
);
