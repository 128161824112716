import { createReducer, on } from '@ngrx/store';
import { BankTab } from '@t12/bank/components/bank/bank.component';
import { nbItemsPageBank } from '@t12/bank/constants/nb-items-page-bank.constant';
import { nbPageBankMax } from '@t12/bank/constants/nb-page-bank-max.constant';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import { bankMaxSize } from '@t12/common/bank/constants/bank-max-size.constant';
import { bankSortItems } from '@t12/common/bank/sort-items/bank-sort-items.function';
import { ItemQuality } from '@t12/common/item/enums/item-quality.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { BankState, initialBankState } from '../index';

export const BankReducer = createReducer(
  initialBankState,

  on(BankActions.openBankSuccess, (bankState: BankState, { bank }) => ({
    ...initialBankState,
    ...bank,
    tab: BankTab.Inventory,
  })),

  on(BankActions.depositToItemSuccess, (bankState: BankState, { item }) => {
    let remainingAmount = item.amount;

    const updatedBank = bankState.items.map((slot) => {
      if (remainingAmount > 0 && slot?.code === item.code) {
        const amountToAdd = Math.min(item.max - slot.amount, remainingAmount);
        remainingAmount -= amountToAdd;
        return { ...slot, amount: slot.amount + amountToAdd };
      }
      return slot;
    });

    while (remainingAmount > 0 && updatedBank.length < bankMaxSize) {
      const amountToAdd = Math.min(item.max, remainingAmount);
      updatedBank.push({ ...item, amount: amountToAdd });
      remainingAmount -= amountToAdd;
    }

    const newAmount = bankState.activeItemAmountMax - item.amount;

    return {
      ...bankState,
      items: updatedBank.sort(bankSortItems),
      depositItem:
        newAmount <= 0
          ? initialBankState.depositItem
          : { ...item, amount: newAmount },
      activeItemAmountMax: newAmount,
    };
  }),

  on(BankActions.depositFromItemSuccess, (bankState: BankState, { item }) => {
    let remainingAmount = item.amount;

    const updatedBank = bankState.items
      .map((slot) => {
        if (remainingAmount > 0 && slot?.code === item.code) {
          const amountToWithdraw = Math.min(slot.amount, remainingAmount);
          remainingAmount -= amountToWithdraw;

          const newAmount = slot.amount - amountToWithdraw;
          return newAmount > 0 ? { ...slot, amount: newAmount } : null;
        }
        return slot;
      })
      .filter(Boolean); // On enlève les slots qui n'ont plus d'items (quantité 0).

    const itemMap = new Map<string, Item>();

    updatedBank.forEach((slot) => {
      if (!slot.code) return;

      const existingItem = itemMap.get(slot.code);

      if (existingItem) {
        const totalAmount = existingItem.amount + slot.amount;
        const amountToKeep = Math.min(totalAmount, slot.max);

        itemMap.set(slot.code, { ...existingItem, amount: amountToKeep });

        const remainingAmountToDistribute = totalAmount - amountToKeep;
        if (remainingAmountToDistribute > 0) {
          itemMap.set(`${slot.code}-remaining`, {
            ...slot,
            amount: remainingAmountToDistribute,
          });
        }
      } else {
        itemMap.set(slot.code, { ...slot });
      }
    });

    // On prépare la nouvelle banque triée et ordonnée.
    let sortedBank = Array(bankMaxSize).fill({ amount: 0 });
    let index = 0;

    // Remplir le tableau avec les items mis à jour et fusionnés.
    itemMap.forEach((item) => {
      if (index < bankMaxSize) {
        sortedBank[index++] = { ...item };
      }
    });

    const newAmount = bankState.activeItemAmountMax - item.amount;

    return {
      ...bankState,
      items: sortedBank.filter((slot) => slot.code).sort(bankSortItems),
      depositItem:
        newAmount <= 0
          ? initialBankState.depositItem
          : {
              ...item,
              amount: newAmount,
            },
      activeItemAmountMax: newAmount,
    };
  }),

  on(BankActions.updateItemsDisplayed, (bankState: BankState) => {
    const currentPage = Math.min(bankState.currentPage, bankMaxSize);
    const startIndex = (currentPage - 1) * nbItemsPageBank;

    return {
      ...bankState,
      displayedItems: bankState.items
        .slice(startIndex, startIndex + nbItemsPageBank)
        .sort(bankSortItems),
      currentPage,
    };
  }),

  on(BankActions.setTab, (bankState: BankState, { tab }) => ({
    ...bankState,
    tab,
    depositItem: initialBankState.depositItem,
    activeItemAmountMax: undefined,
  })),

  on(BankActions.incCurrentPage, (bankState: BankState, { inc }) => {
    const newPage = Math.min(
      Math.max(bankState.currentPage + inc, 1),
      bankState.maxPage,
    );

    return {
      ...bankState,
      currentPage: newPage,
    };
  }),

  on(
    BankActions.setDepositItemSuccess,
    (bankState: BankState, { item, amountMax }) => ({
      ...bankState,
      depositItem: item,
      activeItemAmountMax: amountMax,
    }),
  ),

  on(BankActions.incDepositItemAmount, (bankState: BankState, { inc }) => {
    const newAmount = bankState.depositItem.amount + inc;
    if (newAmount > bankState.activeItemAmountMax) return bankState;

    return {
      ...bankState,
      depositItem:
        newAmount <= 0
          ? initialBankState.depositItem
          : {
              ...bankState.depositItem,
              amount: Math.max(0, newAmount),
            },
    };
  }),

  on(BankActions.setDepositGoldSuccess, (bankState: BankState, { gold }) => ({
    ...bankState,
    depositGold: gold,
  })),

  on(BankActions.depositToGoldSuccess, (bankState: BankState, { gold }) => ({
    ...bankState,
    gold: bankState.gold + gold,
    depositGold: 0,
  })),

  on(BankActions.depositFromGoldSuccess, (bankState: BankState, { gold }) => ({
    ...bankState,
    gold: bankState.gold - gold,
    depositGold: 0,
  })),

  on(BankActions.closeBank, (_: BankState) => ({
    playerId: undefined,
    depositItem: { quality: ItemQuality.Common } as Item,
    depositGold: 0,
    lvl: 1,
    gold: 0,
    items: [],
    currentPage: 1,
    maxPage: nbPageBankMax,
    tab: BankTab.Inventory,
  })),
);
