import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { QuestIcon } from '@t12/common/characters/types/quest-icon.type';
import { IContainer } from '@t12/common/container/interfaces/container.interface';
import { AttackCharacterResult } from '@t12/common/fight/interfaces/attack-character.interface';
import { RewardFight } from '@t12/common/fight/interfaces/reward-fight.interface';
import { AttackKind } from '@t12/common/fight/types/attack-kind.type';
import { HitKind } from '@t12/common/fight/types/hit-kind.type';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { PlayerJobs } from '@t12/common/job/interfaces/player-jobs.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { WorldPosition } from '@t12/common/world/interfaces/world-position.interface';

export const CharactersActions = createActionGroup({
  source: 'CHARACTER',
  events: {
    Reset: emptyProps(),
    'Load player': emptyProps(),
    'Load player success': props<{ player: IPlayer }>(),
    'Init player success': emptyProps(),
    'Load player failed': props<{ error: HttpErrorResponse }>(),
    'Add player': props<{ id: number }>(),
    'Add player success': props<{ player: IPlayer }>(),
    'Add player failed': emptyProps(),
    'Remove player': props<{ id: number }>(),
    'Set characters': props<{ characters: (IMonster | INPC)[] }>(),
    'Set characters success': emptyProps(),
    'Add character': props<{ character: IMonster | INPC }>(),
    'Character is dead': props<{ id: number; kind: CharacterKind }>(),
    'player kill character': props<{ id: number; kind: CharacterKind }>(),
    'Remove character by id': props<{ id: number; kind: CharacterKind }>(),
    'Reset characters': emptyProps(),

    'Local player move': props<{
      direction: Looking;
    }>(),

    Move: props<{
      id: number;
      kind: CharacterKind;
      direction: Looking;
      force?: true;
    }>(),
    'Move step': props<{
      id: number;
      kind: CharacterKind;
      direction: Looking;
    }>(),
    'Idle step': props<{ id: number; kind: CharacterKind }>(),

    'Local player attack': props<{
      attackKind: AttackKind;
      emitDmg?: boolean;
    }>(),

    Attack: props<{
      id: number;
      characterKind: CharacterKind;
      attackKind: AttackKind;
      emitDmg?: boolean;
    }>(),
    'Character attack': props<{
      attackCharacterResult: AttackCharacterResult;
    }>(),
    'Attack physic': props<{
      character: Character;
      emitDmg?: boolean;
    }>(),

    'Attack magic': props<{
      character: Character;
      emitDmg?: boolean;
    }>(),
    'Attack magic failed not enough mana': props<{
      id: number;
      costMana: number;
    }>(),
    'Attack first step': props<{
      character: Character;
      attackKind: AttackKind;
      emitDmg?: boolean;
    }>(),
    'Attack second step': props<{
      character: Character;
      attackKind: AttackKind;
      emitDmg?: boolean;
    }>(),
    'Attack third step': props<{
      character: Character;
      attackKind: AttackKind;
    }>(),

    'Attack try to hit': props<{
      attacker: Character;
      target: Character;
      attackKind: AttackKind;
    }>(),
    'Attack try to hit failed': props<{
      attackKind: AttackKind;
      hit: HitKind;
    }>(),

    'Attack hit': props<{
      attacker: Character;
      target: Character;
      attackKind: AttackKind;
      hit: HitKind;
    }>(),
    'Attack deal damage': props<{
      targetId: number;
      targetKind: CharacterKind;
      damage: number;
    }>(),
    'Add fight': props<{
      attacker: Character;
      target: INPC | IMonster;
    }>(),

    'Npc death': props<{
      attacker: Character;
      target: INPC | IMonster;
    }>(),
    'Npc death success': props<{
      attacker: Character;
      target: INPC | IMonster;
    }>(),

    'Set position x y': props<{
      id: number;
      kind: CharacterKind;
      y: number;
      x: number;
    }>(),
    'Set looking': props<{
      id: number;
      kind: CharacterKind;
      looking: Looking;
    }>(),
    'Set face to player': props<{ id: number; kind: CharacterKind }>(),
    'Set can move': props<{
      id: number;
      kind: CharacterKind;
      canMove: boolean;
    }>(),

    'Add health': props<{ id: number; kind: CharacterKind; health: number }>(),
    'Add mana': props<{ id: number; kind: CharacterKind; mana: number }>(),
    'Add health mana': props<{
      id: number;
      kind: CharacterKind;
      health: number;
      mana: number;
    }>(),

    'Player death': emptyProps(),
    'Apply death sanction': props<{
      id: number;
      worldPosition: WorldPosition;
    }>(),

    'Reward fight': props<{
      target: { id: number; kind: CharacterKind; code: string };
      reward: RewardFight;
    }>(),
    'Reward xp': props<{ xp: number; notification?: boolean }>(),
    'Reward loot': props<{ container: IContainer }>(),
    'Add xp': props<{ id: number; kind: CharacterKind; xp: number }>(),
    'Local level up': props<{ id: number; kind: CharacterKind; xp: number }>(),
    'Level up': props<{ id: number; kind: CharacterKind }>(),

    'Add gold': props<{ gold: number }>(),
    'Set state': props<{
      id: number;
      kind: CharacterKind;
      state: 'fight' | 'passive';
    }>(),

    'Equip item': props<{ item: Item; oldEquipment?: Item }>(),
    'Unequip item': props<{ item: Item }>(),

    'Set quest icon': props<{ codeCharacter: string; questIcon?: QuestIcon }>(),

    'Add new job': props<{ job: PlayerJob }>(),
    'Remove job': props<{ job: PlayerJob }>(),
    'Set jobs': props<{ jobs: PlayerJobs }>(),
    'Set job xp': props<{ jobCode: JobCode; xp: number }>(),
    'Inc Job XP': props<{ jobCode: JobCode }>(),
  },
});
