import { Material } from '@t12/common/container/enums/container-material.enum';

export const getOpeningSoundContainer = (
  containerMaterial: Material,
): string => {
  switch (containerMaterial) {
    case Material.HardWood:
      return 'chest_open_0';
    default:
      return '';
  }
};
