import { createReducer, on } from '@ngrx/store';
import { inventoryMaxSize } from '@t12/common/inventory/constants/inventory-size.constant';
import { sortItemsByTypeNameAmount } from '@t12/common/inventory/functions/sort-items/sortItemsByTypeNameAmount.function';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { InventoryState, inventoryState } from '@t12/inventory/store';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';

export const InventoryReducer = createReducer(
  inventoryState,

  on(InventoryActions.setActiveItem, (inventoryState, { index }) => ({
    ...inventoryState,
    indexInventory:
      inventoryState.items[index].code &&
      inventoryState.indexInventory !== index
        ? index
        : -1,
  })),

  on(InventoryActions.resetActiveItem, (inventoryState) => ({
    ...inventoryState,
    indexInventory: -1,
  })),

  on(InventoryActions.setInventory, (inventoryState, { items }) => ({
    ...inventoryState,
    items: items,
  })),

  on(
    InventoryActions.addItemInInventory,
    (inventoryState: InventoryState, { item, amount }) => {
      let updatedInventory = [...inventoryState.items];
      let remainingAmount = amount;

      updatedInventory = updatedInventory.map((slot) => {
        if (remainingAmount > 0 && slot?.code === item.code) {
          const availableSpace = item.max - slot.amount;
          const amountToAdd = Math.min(availableSpace, remainingAmount);

          remainingAmount -= amountToAdd;

          return {
            ...slot,
            amount: slot.amount + amountToAdd,
          };
        }

        return slot;
      });

      while (remainingAmount > 0) {
        const emptySlotIndex = updatedInventory.findIndex(
          (slot) => !slot?.code,
        );

        if (emptySlotIndex === -1) break;

        const amountToAdd = Math.min(item.max, remainingAmount);
        updatedInventory[emptySlotIndex] = { ...item, amount: amountToAdd };

        remainingAmount -= amountToAdd;
      }

      return {
        ...inventoryState,
        items: updatedInventory.sort(sortItemsByTypeNameAmount),
      };
    },
  ),

  on(
    InventoryActions.addItemsInInventory,
    (inventoryState: InventoryState, { items }) => {
      let updatedInventory = [...inventoryState.items];

      items.forEach((item) => {
        let remainingAmount = item.amount;

        // Mettre à jour les slots existants pour chaque item
        updatedInventory = updatedInventory.map((slot) => {
          if (remainingAmount > 0 && slot?.code === item.code) {
            const availableSpace = item.max - slot.amount;
            const amountToAdd = Math.min(availableSpace, remainingAmount);

            remainingAmount -= amountToAdd;

            return {
              ...slot,
              amount: slot.amount + amountToAdd,
            };
          }

          return slot;
        });

        // Ajouter des items dans les slots vides
        while (remainingAmount > 0) {
          const emptySlotIndex = updatedInventory.findIndex(
            (slot) => !slot?.code,
          );

          if (emptySlotIndex === -1) break;

          const amountToAdd = Math.min(item.max, remainingAmount);
          updatedInventory[emptySlotIndex] = { ...item, amount: amountToAdd };

          remainingAmount -= amountToAdd;
        }
      });

      return {
        ...inventoryState,
        items: updatedInventory.sort(sortItemsByTypeNameAmount),
      };
    },
  ),

  on(
    InventoryActions.removeItemInInventory,
    (inventoryState: InventoryState, { itemCode, amount }) => {
      let updatedInventory = [...inventoryState.items].sort(
        (a, b) => a.amount - b.amount,
      );
      let remainingAmount = amount;

      updatedInventory = updatedInventory.map((slot) => {
        if (remainingAmount > 0 && slot?.code === itemCode) {
          const amountToSubtract = Math.min(slot.amount, remainingAmount);
          remainingAmount -= amountToSubtract;

          const newAmount = slot.amount - amountToSubtract;

          return {
            ...slot,
            amount: newAmount > 0 ? newAmount : 0,
          };
        }

        return slot;
      });

      updatedInventory = updatedInventory.map((slot) =>
        slot?.code === itemCode && slot.amount === 0 ? { amount: 0 } : slot,
      ) as Item[];

      return {
        ...inventoryState,
        items: updatedInventory.sort(sortItemsByTypeNameAmount),
      };
    },
  ),

  on(
    InventoryActions.removeItemsInInventory,
    (inventoryState: InventoryState, { items }) => {
      let updatedInventory = [...inventoryState.items];

      items.forEach(({ code, amount }) => {
        let remainingAmount = amount;

        updatedInventory = updatedInventory.map((slot) => {
          if (remainingAmount > 0 && slot?.code === code) {
            const amountToSubtract = Math.min(slot.amount, remainingAmount);
            remainingAmount -= amountToSubtract;

            const newAmount = slot.amount - amountToSubtract;

            return {
              ...slot,
              amount: newAmount > 0 ? newAmount : 0,
            };
          }

          return slot;
        });
      });

      // Clean up slots with 0 amount
      updatedInventory = updatedInventory.map((slot) =>
        slot?.amount === 0 ? { amount: 0 } : slot,
      ) as Item[];

      return {
        ...inventoryState,
        items: updatedInventory.sort(sortItemsByTypeNameAmount),
      };
    },
  ),

  // TODO Sortir la logique de tri des objets dans un service pour recuper des Item[] correctement triés et fusionnés pour inventaire et banque
  on(InventoryActions.orderInventory, (inventoryState: InventoryState) => {
    const updatedInventory = [...inventoryState.items];
    const itemMap = new Map<string, Item>();

    updatedInventory.forEach((slot, index) => {
      if (!slot.code) return;

      const uniqueKey = `${slot.code}-${slot.quality}-${index}`;
      const existingItem = itemMap.get(uniqueKey);

      if (slot.max === 1) {
        // Directement ajouter les objets non empilables
        itemMap.set(uniqueKey, { ...slot });
      } else if (existingItem) {
        // Gérer les objets empilables
        const totalAmount = existingItem.amount + slot.amount;
        const amountToAdd = Math.min(totalAmount, existingItem.max);

        itemMap.set(uniqueKey, { ...existingItem, amount: amountToAdd });

        const remainingAmount = totalAmount - amountToAdd;
        if (remainingAmount > 0) {
          const remainingKey = `${slot.code}-${remainingAmount}`;
          itemMap.set(remainingKey, { ...slot, amount: remainingAmount });
        }
      } else {
        itemMap.set(uniqueKey, { ...slot });
      }
    });

    let sortedInventory = Array(inventoryMaxSize).fill({ amount: 0 });
    let index = 0;

    itemMap.forEach((item) => {
      if (index < inventoryMaxSize) {
        sortedInventory[index++] = { ...item };
      }
    });

    return {
      ...inventoryState,
      items: sortedInventory.sort(sortItemsByTypeNameAmount),
    };
  }),
);
