import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BankTab } from '@t12/bank/components/bank/bank.component';
import { BankDbService } from '@t12/bank/services/bank-db/bank-db.service';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import {
  getBankGold,
  getBankTab,
  getItemsBank,
} from '@t12/bank/store/selectors/bank.selectors';
import { getPlayerGold } from '@t12/characters/store/selectors/characters.selectors';
import { countItem } from '@t12/common/inventory/functions/count-item/count-item.function';
import { getPlayerInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import {
  catchError,
  filter,
  map,
  mergeMap,
  of,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class BankEffects {
  private _openBank$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.openBank),
      mergeMap(() =>
        this._bankDbService.getBankPlayer().pipe(
          take(1),
          mergeMap((bank) => [
            BankActions.openBankSuccess({ bank }),
            HudDisplayActions.hideHud({ name: 'dialog' }),
            HudDisplayActions.showHud({ name: 'bank' }),
          ]),
          catchError(() => of(BankActions.openBankFailed())),
        ),
      ),
    ),
  );

  private _openBankFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.openBankFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer les informations de la banque',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _deposit$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.deposit),
      withLatestFrom(this._store.select(getBankTab)),
      map(([_, tab]) => {
        switch (tab) {
          case BankTab.Inventory:
            return BankActions.depositTo();
          case BankTab.Bank:
            return BankActions.depositFrom();
          default:
            return BankActions.depositFail();
        }
      }),
    ),
  );

  private _depositFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.depositFail),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _setDepositItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.setDepositItem),
      withLatestFrom(
        this._store.select(getBankTab),
        this._store.select(getPlayerInventory),
        this._store.select(getItemsBank),
      ),
      filter(([{ item }]) => !!item),
      map(([{ item }, tab, inventory, bank]) => {
        if (tab === BankTab.Inventory)
          return BankActions.setDepositItemSuccess({
            item,
            amountMax: countItem(item.code, inventory),
          });
        else if (tab === BankTab.Bank)
          return BankActions.setDepositItemSuccess({
            item,
            amountMax: countItem(item.code, bank),
          });
        else return BankActions.setDepositItemFailed();
      }),
    ),
  );

  private _setDepositItemFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.setDepositItemFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _setDepositGold$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.setDepositGold),
      withLatestFrom(
        this._store.select(getBankTab),
        this._store.select(getPlayerGold),
        this._store.select(getBankGold),
      ),
      filter(([{ gold }]) => !!gold),
      map(([{ gold }, tab, playerGold, bankGold]) => {
        if (tab === BankTab.Inventory)
          return BankActions.setDepositGoldSuccess({
            gold: Math.min(gold, playerGold),
          });
        else if (tab === BankTab.Bank)
          return BankActions.setDepositGoldSuccess({
            gold: Math.min(gold, bankGold),
          });
        else return BankActions.setDepositGoldFailed();
      }),
    ),
  );

  private _setDepositGoldFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(BankActions.setDepositGoldFailed),
        tap(() =>
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          ),
        ),
      ),
    { dispatch: false },
  );

  private _updateItemsDisplayed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        BankActions.openBankSuccess,
        BankActions.depositToItemSuccess,
        BankActions.depositFromItemSuccess,
      ),
      map(() => BankActions.updateItemsDisplayed()),
    ),
  );

  private _closeBank$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BankActions.closeBank),
      map(() => HudDisplayActions.hideHud({ name: 'bank' })),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _bankDbService: BankDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
