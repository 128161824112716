import { ChatLogKind } from "../enums/chat-log-kind.enums";

export const allChatCommands = {
  [ChatLogKind.Roll]: ["roll", "r"],
  [ChatLogKind.Action]: ["action", "me"],
  [ChatLogKind.All]: ["all", "a"],
  [ChatLogKind.Talk]: ["say", "s"],
  [ChatLogKind.Pm]: ["mp", "w", "pm"],
  [ChatLogKind.Party]: ["g", "party", "p"],
};
