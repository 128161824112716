import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';

export const isNPC = (
  character: IPlayer | IMonster | INPC,
): character is INPC => {
  return character.kind === CharacterKind.NPC;
};
