import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  getPlayer,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { RewardFight } from '@t12/common/fight/interfaces/reward-fight.interface';
import { WorldPosition } from '@t12/common/world/interfaces/world-position.interface';
import { environment } from '@t12/environment';
import { AuthenticationService } from '@t12/user/services/auth/authentication.service';
import { LocalService } from '@t12/utils/services/local/local.service';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { Observable, take } from 'rxjs';
import { PlayerKillCharacterDto } from '../../../../../../back/src/fight/dto/player-kill-character.dto';
import { PlayerUpdateDto } from '../../../../../../back/src/player/dto/player-update.dto';

@Injectable({
  providedIn: 'root',
})
export class PlayerDbService {
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _localService: LocalService,
    private readonly _http: HttpClient,
    private readonly _utilsService: UtilsService,
  ) {}

  // Argument : ID joueur, ID personnage
  // Résultat : Retourne les infos d'un personnage stocké en base de donnée
  public getPlayerCharacter$(): Observable<IPlayer> {
    const id = this._localService.getJsonValue('characterId');
    return this._http.get(`${environment(`player/id/${id}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<IPlayer>;
  }

  public getMinimalPlayerCharacter(playerId): Observable<IPlayer> {
    return this._http.get(`${environment(`player/infos/id/${playerId}`).uri}`, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<IPlayer>;
  }

  // Argument : ID joueur, ID personnage
  // Résultat : Retourne les infos d'un personnage stocké en base de donnée
  public playerKillCharacter(
    body: PlayerKillCharacterDto,
  ): Observable<RewardFight> {
    return this._http.post(`${environment(`player/kill`).uri}`, body, {
      headers: {
        authorization: `Bearer ${this._authService.getToken()}`,
      },
    }) as Observable<RewardFight>;
  }

  // Arguments : Id du personnage à modifier
  // Résultat : Met à jour les infos d'un personnage existant
  public updatePlayer(): Observable<any> {
    const player = this._utilsService.getSelect(getPlayer);
    const { looking, position } = player;
    const playerUpdateDto: PlayerUpdateDto = {
      looking,
      positionX: position.x,
      positionY: position.y,
    };

    return this._http.patch(
      `${environment(`player/id/${player.id}`).uri}`,
      playerUpdateDto,
      {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      },
    );
  }

  // Arguments : Id du personnage à modifier
  // Résultat : Met à jour les infos d'un personnage existant
  public playerDeath(): Observable<WorldPosition> {
    const playerId = this._utilsService.getSelect(getPlayerID);

    return this._http
      .get(`${environment(`player/${playerId}/death`).uri}`, {
        headers: {
          authorization: `Bearer ${this._authService.getToken()}`,
        },
      })
      .pipe(take(1)) as Observable<WorldPosition>;
  }
}
