import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Group } from '@t12/common/socials/interfaces/group.interface';
import { PlayerFriendRequest } from '@t12/common/socials/interfaces/player-friend-request.interface';
import { PlayerFriend } from '@t12/common/socials/interfaces/player-friend.interface';
import { PlayerGroupInfos } from '@t12/common/socials/interfaces/player-group-infos.interface';
import { PlayerGroupRequest } from '@t12/common/socials/interfaces/player-group-request.interface';
import { PlayerLogged } from '@t12/common/socials/interfaces/player-logged.interface';
import { SocialTab } from '../../enums/social-tab.enum';

export const SocialsActions = createActionGroup({
  source: 'SOCIALS',
  events: {
    'Set tab': props<{ tab: SocialTab }>(),

    'Get friends': emptyProps(),
    'Get friends failed': emptyProps(),

    'Set friends': props<{ friends: PlayerFriend[] }>(),

    'Inc friends page': props<{ inc: 1 | -1 }>(),

    'Update displayed friends': emptyProps(),

    'Add friend': props<{ playerFriend: PlayerFriend }>(),
    'Add friend failed': emptyProps(),

    'Player logged in': props<{ player: PlayerLogged }>(),
    'Friend logged in': props<{ player: PlayerLogged }>(),
    'Player logged out': props<{ player: PlayerLogged }>(),
    'Friend logged out': props<{ player: PlayerLogged }>(),

    'Set friend online status': props<{ name: string; online: boolean }>(),

    'Delete friend': props<{ name: string }>(),
    'Delete friend success': props<{ name: string }>(),
    'Delete friend failed': emptyProps(),

    'Get friend requests': emptyProps(),
    'Set friend requests': props<{ friendRequests: PlayerFriendRequest[] }>(),

    'Send friend request': props<{ name: string }>(),
    'Send friend request failed': emptyProps(),
    'Send friend request failed already friend': emptyProps(),
    'Send friend request failed not found': emptyProps(),
    'Send friend request failed already requested': emptyProps(),
    'Send friend request success': props<{ name: string }>(),

    'Accept friend request': props<{ name: string }>(),
    'Add friend request': props<{ friendRequest: PlayerFriendRequest }>(),
    'Denied friend request': props<{ name: string }>(),
    'Denied friend request failed': emptyProps(),
    'Remove friend request': props<{ name: string }>(),
    'Update displayed friend requests': emptyProps(),

    'Send pm': props<{ name: string }>(),

    'Get group requests': emptyProps(),
    'Get group requests failed': emptyProps(),
    'Set group requests': props<{ groupRequests: PlayerGroupRequest[] }>(),

    'Send group request': props<{ name: string }>(),
    'Send group request failed': emptyProps(),
    'Send group request failed not leader': emptyProps(),
    'Send group request failed already in group': emptyProps(),
    'Send group request failed not found': emptyProps(),
    'Send group request failed max size group': emptyProps(),
    'Send group request failed already requested': emptyProps(),
    'Send group request success': props<{ name: string }>(),
    'Add group request': props<{ groupRequest: PlayerGroupRequest }>(),

    'Get group': emptyProps(),
    'Get group failed': emptyProps(),
    'Set group': props<{ group: Group }>(),

    'Accept group request': props<{ name: string }>(),
    'Add group Player': props<{ playerGroup: PlayerGroupInfos }>(),
    'Add group Player failed': emptyProps(),
    'Denied group request': props<{ name: string }>(),
    'Denied group request failed': emptyProps(),
    'Remove group request': props<{ name: string }>(),
    'Remove group requests': emptyProps(),

    'Set group leader': props<{ leaderName: string }>(),
    'Set group leader success': props<{ leader: PlayerFriendRequest }>(),
    'Set group leader failed not in group': emptyProps(),
    'Set group leader failed not leader': emptyProps(),
    'Set group leader failed already leader': emptyProps(),
    'Set group leader failed': emptyProps(),

    'Leave group': emptyProps(),
    'Leave group success': emptyProps(),
    'Leave group failed': emptyProps(),
    'Leave group failed not in group': emptyProps(),

    'Player join group': props<{ player: PlayerGroupInfos }>(),

    'Player left group': props<{ name: string }>(),
    'Player left group success': props<{ name: string }>(),
    'Kick group player': props<{ name: string }>(),
    'Kick group player success': props<{ name: string }>(),
    'Kick group player failed not in group': emptyProps(),
    'Kick group player failed not leader': emptyProps(),
    'Kick group player failed': emptyProps(),
    'Kick from group': emptyProps(),

    'Set player group status online': props<{
      name: string;
      online: boolean;
    }>(),

    'Group disband': emptyProps(),
    'Group disband success': emptyProps(),
  },
});
