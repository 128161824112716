import { HttpErrorResponse } from '@angular/common/http';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { TextDialog } from '@t12/common/dialog/interfaces/text-dialog.interface';

export const DIALOG_FEATURE_KEY = 'dialog';

export interface DialogState {
  character?: INPC | IMonster;
  texts: TextDialog[];
  index: number;
  error?: HttpErrorResponse;
}

export const initialDialogState: DialogState = {
  character: null,
  texts: [],
  index: 0,
};
