import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { SocialsDbService } from '../../../services/socials-db/socials-db.service';
import { SocialsActions } from '../../actions/socials.actions';

@Injectable()
export class GroupRequestsEffects {
  private _getGroupRequests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.getGroupRequests),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([, playerId]) =>
        this._socialsDbService.getPlayerGroupRequests(playerId).pipe(
          map((groupRequests) =>
            SocialsActions.setGroupRequests({ groupRequests }),
          ),
          catchError(() => of(SocialsActions.getGroupRequestsFailed())),
        ),
      ),
    ),
  );

  private _sendGroupRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.sendGroupRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.sendGroupRequest(playerId, name).pipe(
          switchMap((response) => {
            if ('lvl' in response) {
              return [
                SocialsActions.removeGroupRequests(),
                SocialsActions.addGroupPlayer({ playerGroup: response }),
              ];
            } else {
              return of(
                SocialsActions.sendGroupRequestSuccess({
                  name: response.name,
                }),
              );
            }
          }),
          catchError(({ error }: HttpErrorResponse) => {
            const action =
              error?.message === 'NOT_LEADER_GROUP'
                ? SocialsActions.sendGroupRequestFailedNotLeader()
                : error?.message === 'NOT_FOUND'
                  ? SocialsActions.sendGroupRequestFailedNotFound()
                  : error?.message === 'ALREADY_IN_GROUP'
                    ? SocialsActions.sendGroupRequestFailedAlreadyInGroup()
                    : error?.message === 'MAX_SIZE_GROUP'
                      ? SocialsActions.sendGroupRequestFailedMaxSizeGroup()
                      : error?.message === 'ALREADY_REQUESTED'
                        ? SocialsActions.sendGroupRequestFailedAlreadyRequested()
                        : SocialsActions.sendGroupRequestFailed();
            return of(action);
          }),
        ),
      ),
    ),
  );

  private _sendGroupRequestSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendGroupRequestSuccess),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'settings',
            `Invitation envoyée à ${name}`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedNotLeader$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendGroupRequestFailedNotLeader),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Vous devez être le chef de groupe pour ça.`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedNotFound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendGroupRequestFailedNotFound),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Aucun joueur trouvé...`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedAlreadyInGroup$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendGroupRequestFailedAlreadyInGroup),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Ce joueur est déjà dans un groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedMaxSizeGroup$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendGroupRequestFailedMaxSizeGroup),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Limite de taille de groupe atteinte`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailedAlreadyRequested$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendGroupRequestFailedAlreadyRequested),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Une demande est déjà en cours.`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendGroupRequestFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          SocialsActions.sendGroupRequestFailed,
          SocialsActions.addGroupPlayerFailed,
          SocialsActions.deniedGroupRequestFailed,
        ),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _addGroupRequest$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.addGroupRequest),
        tap(({ groupRequest }) => {
          this._notificationService.addNotification(
            'settings',
            `Vous avez reçu une invitation de ${groupRequest.name}`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _acceptGroupRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.acceptGroupRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.acceptGroupRequest(playerId, name).pipe(
          switchMap(() => [
            SocialsActions.removeGroupRequests(),
            SocialsActions.getGroup(),
          ]),
          catchError(() => of(SocialsActions.addGroupPlayerFailed())),
        ),
      ),
    ),
  );

  private _deniedGroupRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.deniedGroupRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.deletePlayerGroupRequest(playerId, name).pipe(
          map(() => SocialsActions.removeGroupRequest({ name })),
          catchError(() => of(SocialsActions.deniedGroupRequestFailed())),
        ),
      ),
    ),
  );

  // private _removeFriendRequest$ = createEffect(
  //   () =>
  //     this._actions$.pipe(
  //       ofType(SocialsActions.removeFriendRequest),
  //       tap(({ name }) => {
  //         this._notificationService.addNotification(
  //           'error',
  //           `Demande de ${name} refusée`,
  //         );
  //       }),
  //     ),
  //   { dispatch: false },
  // );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socialsDbService: SocialsDbService,
    private readonly _store: Store,
  ) {}
}
