import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import {
  getBankCurrentPage,
  getBankGold,
  getBankMaxPage,
  getBankTab,
  getDepositGold,
  getDepositItem,
  getDisplayedItemsBank,
} from '@t12/bank/store/selectors/bank.selectors';
import { getPlayerGold } from '@t12/characters/store/selectors/characters.selectors';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { InventoryComponent } from '@t12/inventory/components/inventory/inventory.component';
import { getPlayerInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { ButtonComponent } from '@t12/ui/components/button/button.component';
import { CornersHalfComponent } from '@t12/ui/components/corners-half/corners-half.component';
import { SlotItemComponent } from '@t12/ui/components/slot-item/slot-item.component';
import { PopoverDirective } from '@t12/ui/directives/popover.directive';
import { UnSubscriberComponent } from '@t12/utils/component/unsubscriber.component';
import { Observable } from 'rxjs';

export enum BankTab {
  Inventory = 'inventory',
  Bank = 'bank',
}

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.less'],
  imports: [
    CornersHalfComponent,
    NgClass,
    NgFor,
    SlotItemComponent,
    NgIf,
    PopoverDirective,
    FormsModule,
    ButtonComponent,
    InventoryComponent,
    AsyncPipe,
  ],
})
export class BankComponent extends UnSubscriberComponent implements OnInit {
  public activeItem: Observable<Item> = this._store.select(getDepositItem);
  public activeTab: Observable<BankTab> = this._store.select(getBankTab);
  public amountGold: number = 0;
  public bankGold = this._store.select(getBankGold);
  public currentPage = this._store.select(getBankCurrentPage);
  public depositGoldMax: Observable<number> =
    this._store.select(getDepositGold);
  public displayedItemBank = this._store.select(getDisplayedItemsBank);
  public displayedItems: Observable<Item[]>;
  public inventory = this._store.select(getPlayerInventory);
  public maxPage = this._store.select(getBankMaxPage);
  public playerGold = this._store.select(getPlayerGold);
  public readonly BankTab = BankTab;

  constructor(private readonly _store: Store) {
    super();
  }

  ngOnInit(): void {
    this.activeTab.pipe(this.takeUntilDestroyed()).subscribe((tab) => {
      const isInventoryTab = tab === BankTab.Inventory;
      this.depositGoldMax = isInventoryTab ? this.playerGold : this.bankGold;
      this.displayedItems = isInventoryTab
        ? this.inventory
        : this.displayedItemBank;
      this.amountGold = 0;
    });
  }

  onAmountChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const gold = Number(inputElement.value);

    this._store.dispatch(BankActions.setDepositGold({ gold }));
  }

  public setTab(tab: BankTab) {
    this._store.dispatch(BankActions.setTab({ tab }));
  }

  public incPageBank(inc: 1 | -1) {
    this._store.dispatch(BankActions.incCurrentPage({ inc }));
  }

  public setActiveItem(item: Item) {
    if (!item) return;
    this._store.dispatch(BankActions.setDepositItem({ item }));
  }

  public incActiveItemAmount(inc: 1 | -1) {
    this._store.dispatch(BankActions.incDepositItemAmount({ inc }));
  }

  public deposit() {
    this._store.dispatch(BankActions.deposit());
    this.amountGold = 0;
  }
}
