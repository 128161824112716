import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { IHarvestPoint } from '@t12/common/job/interfaces/harvest-point.interface';
import { PlayerJob } from '@t12/common/job/interfaces/player-job.interface';
import { IJobRecipe } from '@t12/common/job/interfaces/recipe.interface';
import { Workshop } from '@t12/common/job/interfaces/workshop.interface';
import { JobCode } from '@t12/common/job/types/job-code.type';
import { TabJob } from '@t12/jobs/enums/tab-job.enum';

export const JobActions = createActionGroup({
  source: 'JOB',
  events: {
    Init: emptyProps(),
    'Learn knowledge': props<{ item: Item }>(),

    'Learn job': props<{ itemCode: string }>(),
    'Learn job success': props<{ job: PlayerJob; itemCode: string }>(),
    'Learn job fail': emptyProps(),
    'Learn job failed already know': emptyProps(),
    'Learn job failed reached max': emptyProps(),
    'Forget job': props<{ job: PlayerJob }>(),

    'Learn recipe': props<{ item: Item }>(),
    'Learn recipe success': props<{ recipe: IJobRecipe; item: Item }>(),
    'Learn recipe fail': emptyProps(),
    'Learn recipe fail already known': emptyProps(),
    'Learn recipe fail missing job': emptyProps(),
    'Learn recipe fail not enough xp': props<{
      playerJobXp: number;
      requiredXp: number;
    }>(),

    harvest: props<{ harvestPoint: IHarvestPoint }>(),
    'Harvest success': props<{ harvestPoint: IHarvestPoint }>(),
    'Harvest failed': props<{ position: Position }>(),
    'Harvest failed wrong job': props<{ jobCode: JobCode }>(),
    'Harvest failed required xp low': props<{
      xp: number;
      requiredXp: number;
    }>(),

    'Open workshop': props<{ workshop: Workshop }>(),
    'Open workshop success': props<{ workshop: Workshop }>(),
    'Open workshop failed wrong job': props<{ jobCode: JobCode }>(),
    'Open workshop failed required xp low': props<{
      xp: number;
      requiredXp: number;
    }>(),
    'Close workshop': emptyProps(),

    'Set active job': props<{ jobCode: JobCode }>(),

    'Set recipe filter': props<{ filter: ItemType }>(),
    'Set recipe filters': props<{ item?: Item }>(),
    'Set recipes': props<{ jobCode: JobCode; recipes: IJobRecipe[] }>(),
    'Set recipes fail': emptyProps(),
    'Displayed recipes': props<{ jobCode: JobCode }>(),
    'Ordered recipes': emptyProps(),

    'Set active tab': props<{ tab: TabJob }>(),
    'Inc current page': props<{ inc: 1 | -1 }>(),
    'Set max page': props<{ maxPage: number }>(),

    'Craft recipe': props<{ recipe: IJobRecipe }>(),
    'Craft recipe success': props<{ recipe: IJobRecipe }>(),
    'Craft recipe critical': emptyProps(),

    'Craft recipe fail': emptyProps(),
    'Craft recipe fail no job': props<{ jobCode: JobCode }>(),
    'Craft recipe fail no workshop': emptyProps(),
    'Craft recipe fail not enough ingredients': emptyProps(),
    'Craft recipe fail not enough space': emptyProps(),
    'Craft recipe fail no product': emptyProps(),
  },
});
