import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BankTab } from '@t12/bank/components/bank/bank.component';
import { BankState } from '@t12/bank/store';
import { Item } from '@t12/common/item/interfaces/item.interface';

export const BankActions = createActionGroup({
  source: 'BANK',
  events: {
    'Open bank': emptyProps(),
    'Open bank success': props<{ bank: BankState }>(),
    'Open bank failed': emptyProps(),

    'Set bank': props<{ bank: BankState }>(),
    'Update items displayed': emptyProps(),

    Deposit: emptyProps(),
    'Deposit fail': emptyProps(),

    'Deposit to': emptyProps(),
    'Deposit to success': props<{ item?: Item; gold?: number }>(),
    'Deposit to failed bank full': emptyProps(),
    'Deposit to failed not enough gold': emptyProps(),
    'Deposit to failed not enough item': emptyProps(),
    'Deposit to item success': props<{ item: Item }>(),
    'Deposit to gold success': props<{ gold: number }>(),

    'Deposit from': emptyProps(),
    'Deposit from success': props<{ item?: Item; gold?: number }>(),
    'Deposit from failed inventory full': emptyProps(),
    'Deposit from failed not enough gold': emptyProps(),
    'Deposit from failed not enough item': emptyProps(),
    'Deposit from item success': props<{ item: Item }>(),
    'Deposit from gold success': props<{ gold: number }>(),

    'Set deposit item': props<{ item: Item }>(),
    'Set deposit item success': props<{ item: Item; amountMax: number }>(),
    'Set deposit item failed': emptyProps(),
    'Inc deposit item amount': props<{ inc: 1 | -1 }>(),

    'Set deposit gold': props<{ gold: number }>(),
    'Set deposit gold success': props<{ gold: number }>(),
    'Set deposit gold failed': emptyProps(),

    'Set tab': props<{ tab: BankTab }>(),
    'Inc current page': props<{ inc: 1 | -1 }>(),

    'Close bank': emptyProps(),
  },
});
