import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { OptionDialog } from '@t12/common/dialog/interfaces/option-dialog.interface';
import { TextDialog } from '@t12/common/dialog/interfaces/text-dialog.interface';

export const DialogActions = createActionGroup({
  source: 'DIALOG',
  events: {
    Init: emptyProps(),
    'start conversation': props<{ npc: INPC }>(),
    'start narration': props<{
      textsDialog: TextDialog[];
      npc: INPC | IMonster;
    }>(),
    'Set dialog': props<{ textsDialog: TextDialog[] }>(),
    'Continue  conversation': emptyProps(),

    'Choose option': props<{ option: OptionDialog }>(),

    'End conversation': emptyProps(),
    'End conversation success': emptyProps(),

    'Next message': emptyProps(),
    'Next message option': props<{ option: OptionDialog }>(),
    'Set options validations': props<{ options: OptionDialog[] }>(),
  },
});
