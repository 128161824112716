import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getPlayerPositionInFront,
  getPlayerID,
} from '@t12/characters/store/selectors/characters.selectors';
import { ContainerKind } from '@t12/common/container/enums/container-kind.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';
import { getOpeningSoundContainer } from '@t12/container/constants/get-opening-sound-containter.cnstant';
import { nbItemsPageContainer } from '@t12/container/constants/max-items-page-container.constant';
import { ContainerDbService } from '@t12/container/services/container-db/container-db.service';
import { ContainerActions } from '@t12/container/store/actions/container.actions';
import {
  getContainerCurrentPage,
  getContainerItems,
  getContainerKind,
  getContainerID,
} from '@t12/container/store/selectors/container.selectors';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getFreeInventorySlotAmount } from '@t12/inventory/store/selectors/inventory.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { getHudContainer } from '@t12/overlay/store/selectors/hud-display.selectors';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { SocketService } from '@t12/utils/services/socket/socket.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  catchError,
  concatMap,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class ContainerEffects {
  private _openContainer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.openContainer),
      throttleTime(200),
      withLatestFrom(this._store.select(getHudContainer)),
      map(([{ container }, isVisibleLootBox]) => {
        if (!isVisibleLootBox) {
          return ContainerActions.openContainerSuccess({ container });
        } else return ContainerActions.pickItemContainer({});
      }),
    ),
  );

  private _openContainerSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.openContainerSuccess),
      withLatestFrom(this._store.select(getPlayerPositionInFront)),
      switchMap(([{ container }, position]) =>
        this._containerDbService.getContainer(container.kind).pipe(
          take(1),
          concatMap((container) => [
            ContainerActions.setContainer({ container, position }),
            HudDisplayActions.showHud({ name: 'container' }),
          ]),
          catchError(() =>
            of(ContainerActions.openContainerFailed({ container, position })),
          ),
        ),
      ),
    ),
  );

  private _openContainerFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.openContainerFailed),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          "Ce coffre de butin n'est plus disponible",
        );
      }),
      map(({ container, position: { x, y } }) => {
        const entityMap = {
          [ContainerKind.LOOT]: 'container',
          [ContainerKind.HARVEST]: 'harvestPoint',
        };
        const entity = entityMap[container.kind];

        return WorldActions.removeEntity({ x, y, entity });
      }),
    ),
  );

  private _openSoundContainer$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ContainerActions.setContainer),
        tap(({ container }) => {
          const openSong = getOpeningSoundContainer(container.material);
          this._audioService.playSound('miscs', openSong, 'ogg');
        }),
      ),
    { dispatch: false },
  );

  private _pickItemContainer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.pickItemContainer),
      throttleTime(200),
      withLatestFrom(
        this._store.select(getContainerItems),
        this._store.select(getContainerCurrentPage),
      ),
      switchMap(([{ index }, items, currentPage]) => {
        const pickItem =
          items[index + (currentPage - 1) * nbItemsPageContainer || 0];

        if (!pickItem) return of(ContainerActions.pickItemFailedNoItem({}));

        return this._store.select(getFreeInventorySlotAmount(pickItem)).pipe(
          take(1),
          map((freeSlotsAmount) => {
            const pickedAmount = Math.min(freeSlotsAmount, pickItem.amount);

            if (freeSlotsAmount > 0 && freeSlotsAmount >= pickedAmount) {
              return ContainerActions.pickItemSuccess({
                item: pickItem,
                amount: pickedAmount,
              });
            } else {
              return ContainerActions.pickItemFailedNotEnoughPlace();
            }
          }),
        );
      }),
    ),
  );

  private _pickItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.pickItemSuccess),
      tap(({ item, amount }) => {
        this._audioService.playSound('miscs', 'drop_leather', 'ogg');
        this._notificationService.addNotification(
          'validation',
          `+${amount} ${item.name}`,
          5000,
          item.img,
        );
      }),
      switchMap(({ item, amount }) =>
        this._containerDbService.pickContainerItem(item.code).pipe(
          take(1),
          switchMap((item: Item) => [
            ContainerActions.removeItem({ itemCode: item.code, amount }),
            InventoryActions.addItemInInventory({ item, amount }),
          ]),
          catchError(() =>
            of(
              ContainerActions.pickItemFailedNoItem({
                itemCode: item.code,
                amount,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  private _removeItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.removeItem),
      withLatestFrom(
        this._store.select(getContainerItems),
        this._store.select(getPlayerPositionInFront),
        this._store.select(getContainerKind),
        this._store.select(getPlayerID),
      ),
      filter(([_, items]) => items.length === 0),
      switchMap(([_, __, { x, y }, kind, id]) => {
        const entityMap = {
          [ContainerKind.LOOT]: 'container',
          [ContainerKind.HARVEST]: 'harvestPoint',
        };
        const entity = entityMap[kind];

        if (entity === 'container')
          this._socketService.emit('player-container-remove', {
            id,
            x,
            y,
            entity,
          });

        return entity
          ? [
              HudDisplayActions.hideHud({ name: 'container' }),
              HudDisplayActions.hideHud({ name: 'inventory' }),
              ContainerActions.init(),
              WorldActions.removeEntity({ x, y, entity }),
            ]
          : [];
      }),
    ),
  );

  private _pickItemFailedNotEnoughPlace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ContainerActions.pickItemFailedNotEnoughPlace),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            "Vous n'avez pas assez de place dans votre sac",
          );
        }),
      ),
    { dispatch: false },
  );

  private _pickItemFailedNoItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.pickItemFailedNoItem),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          "Ce butin n'est plus disponible",
        );
      }),
      map(({ itemCode, amount }) =>
        ContainerActions.removeItem({ itemCode, amount }),
      ),
    ),
  );

  private _updateItemsDisplayed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ContainerActions.setContainer,
        ContainerActions.incCurrentPage,
        ContainerActions.removeItem,
      ),
      map(() => ContainerActions.updateItemsDisplayed()),
    ),
  );

  private _closeContainer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.closeContainer),
      map(() => HudDisplayActions.hideHud({ name: 'container' })),
    ),
  );

  private _playerPickAnItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContainerActions.playerPickAnItem),
      withLatestFrom(this._store.select(getContainerID)),
      filter(
        ([{ containerId }, containerIdActive]) =>
          containerId === containerIdActive,
      ),
      map(([{ itemCode, amount }]) =>
        ContainerActions.removeItem({ itemCode, amount }),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _containerDbService: ContainerDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socketService: SocketService,
    private readonly _store: Store,
  ) {}
}
