import { BankTab } from '@t12/bank/components/bank/bank.component';
import { nbPageBankMax } from '@t12/bank/constants/nb-page-bank-max.constant';
import { Bank } from '@t12/common/bank/interfaces/bank.interface';
import { ItemQuality } from '@t12/common/item/enums/item-quality.enum';
import { Item } from '@t12/common/item/interfaces/item.interface';

export const BANK_FEATURE_KEY = 'bank';

export interface BankState extends Bank {
  depositItem?: Item;
  depositGold?: number;
  activeItemAmountMax?: number;
  displayedItems?: Item[];
  currentPage?: number;
  maxPage?: number;
  tab?: BankTab;
}

export const initialBankState: BankState = {
  playerId: undefined,
  depositItem: { quality: ItemQuality.Common } as Item,
  lvl: 1,
  gold: 0,
  items: [],
  displayedItems: [],
  currentPage: 1,
  maxPage: nbPageBankMax,
  tab: BankTab.Inventory,
};
