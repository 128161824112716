import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getPlayerName } from '@t12/characters/store/selectors/characters.selectors';
import { SocialsState } from '../index';

const selectRoot = createFeatureSelector<SocialsState>('socials');

export const getSocialsActiveTab = createSelector(
  selectRoot,
  ({ activeTab }: SocialsState) => activeTab,
);

export const getFriendRequests = createSelector(
  selectRoot,
  ({ friendRequests: { displayedFriends } }: SocialsState) => displayedFriends,
);

export const getFriendRequestsAmount = createSelector(
  selectRoot,
  ({ friendRequests: { list } }: SocialsState) => list.length,
);

export const getFriends = createSelector(
  selectRoot,
  ({ friends: { displayedFriends } }: SocialsState) => displayedFriends,
);

export const getFriendsCurrentPage = createSelector(
  selectRoot,
  ({ friends: { currentPage } }: SocialsState) => currentPage,
);

export const getFriendsMaxPage = createSelector(
  selectRoot,
  ({ friends: { maxPage } }: SocialsState) => maxPage,
);

export const isFriend = (name: string) =>
  createSelector(getFriends, (friends) => {
    return !!friends.find((f) => f.name === name);
  });

export const getGroupRequests = createSelector(
  selectRoot,
  ({ groupRequests }: SocialsState) => groupRequests,
);

export const isGroupLeaderByName = (name: string) =>
  createSelector(selectRoot, ({ group: { leaderName } }) => {
    return leaderName === name;
  });

export const isGroupLeader = createSelector(
  selectRoot,
  getPlayerName,
  ({ group: { leaderName } }, playerName) => {
    return leaderName === playerName;
  },
);

export const getPlayersGroup = createSelector(
  selectRoot,
  ({ group }: SocialsState) => group?.playersInfos,
);

export const getPlayersGroupSize = createSelector(
  selectRoot,
  ({ group }: SocialsState) => group?.playersInfos.length,
);

export const isPlayerInGroup = createSelector(
  selectRoot,
  ({ group }: SocialsState) => !!group.playersInfos && !!group.leaderName,
);

export const canInviteInGroup = createSelector(
  isGroupLeader,
  isPlayerInGroup,
  (isLeader, isInGroup) => isLeader || !isInGroup,
);

export const arePlayersInSameGroup = (id1: number, id2: number) =>
  createSelector(selectRoot, ({ group: { playersInfos } }) => {
    const hasId1 = playersInfos.some((player) => player.id === id1);
    const hasId2 = playersInfos.some((player) => player.id === id2);
    return hasId1 && hasId2;
  });

export const isPlayerInGroupByName = (name: string) =>
  createSelector(selectRoot, ({ group: { playersInfos } }) => {
    return !!playersInfos.find((player) => player.name === name);
  });
