import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatTab } from '@t12/chat/enums/chat-tab.enum';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { Looking } from '@t12/common/characters/types/looking.type';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { AttackKind } from '@t12/common/fight/types/attack-kind.type';
import { HitKind } from '@t12/common/fight/types/hit-kind.type';
import { attackLabel } from '@t12/fight/constants/attack-label.constant';
import { hitLabel } from '@t12/fight/constants/hit-label.constant';

@Injectable({
  providedIn: 'root',
})
export class FightManagerService {
  constructor(private readonly _store: Store) {}

  // Argument : Attaquant, Cible à toucher, type d'attaque, type de coup
  // Résultat : Retourne les dégats totaux et ceux potentiellement réduits

  // Argument : Dégâts et réduction, attaquant, cible, type de coup, type d'attaque
  // Résultat : Retourne les dégâts totaux et ceux potentiellement réduits
  public displayChatLogHit(
    attacker: { name: string; looking: Looking; kind: CharacterKind },
    target: { name: string; looking: Looking; kind: CharacterKind },
    [dmg, reduce]: number[],
    attack: AttackKind,
    hit: HitKind,
  ): void {
    const { name: attackerName, looking: attackerLooking } = attacker;
    const { name: targetName, looking: targetLooking } = target;
    const targetIsPlayer = target.kind === CharacterKind.PLAYER;
    const typeMe = targetIsPlayer ? ChatLogKind.Malus : ChatLogKind.Bonus;
    const typeMeOpposite = targetIsPlayer
      ? ChatLogKind.Bonus
      : ChatLogKind.Malus;
    const labelAttack = attackLabel[attack];
    const labelDmg = `a infligé ${dmg} dégâts (${labelAttack}) à ${targetName}`;
    const isBehind =
      attackerLooking === targetLooking && attackerLooking !== undefined;
    const reduceLabel = reduce > 0 ? `+${reduce}` : reduce.toString();

    let message: string;

    switch (hit) {
      case 'block':
      case 'parry':
      case 'crit':
        message = `${labelDmg} (${hitLabel[hit]}: ${reduceLabel} dégâts).`;
        this._store.dispatch(
          ChatActions.addChatLog({
            tab: ChatTab.FIGHT,
            name: attackerName,
            text: message,
            kind: typeMe,
          }),
        );
        break;
      case 'hit':
        if (attack === 'physic' && isBehind) {
          message = `${labelDmg} (Coup dans le dos).`;
          this._store.dispatch(
            ChatActions.addChatLog({
              tab: ChatTab.FIGHT,
              name: attackerName,
              text: message,
              kind: typeMe,
            }),
          );
        } else {
          this._store.dispatch(
            ChatActions.addChatLog({
              tab: ChatTab.FIGHT,
              name: attackerName,
              text: labelDmg,
              kind: typeMe,
            }),
          );
        }
        break;
      case 'avoid':
        message = `a esquivé le coup de ${attackerName}.`;
        this._store.dispatch(
          ChatActions.addChatLog({
            tab: ChatTab.FIGHT,
            name: targetName,
            text: message,
            kind: typeMeOpposite,
          }),
        );
        break;
      case 'miss':
        message = `a raté ${targetName}.`;
        this._store.dispatch(
          ChatActions.addChatLog({
            tab: ChatTab.FIGHT,
            name: attackerName,
            text: message,
            kind: typeMeOpposite,
          }),
        );

        break;
      default:
        this._store.dispatch(
          ChatActions.addChatLog({
            tab: ChatTab.FIGHT,
            name: attackerName,
            text: labelDmg,
            kind: typeMe,
          }),
        );
    }
  }
}
