import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayer } from '@t12/characters/store/selectors/characters.selectors';
import { ChatTab } from '@t12/chat/enums/chat-tab.enum';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { calculateXpForNextLevel } from '@t12/common/player/constants/next-level-xp.constant';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import { filter, map, switchMap, tap, withLatestFrom, from } from 'rxjs';

@Injectable()
export class FightCharacterEffects {
  private _rewardFight$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardFight),
      switchMap(({ reward: { xp, container, incJob } }) =>
        from(
          [
            xp > 0 ? CharactersActions.rewardXp({ xp }) : null,
            container ? CharactersActions.rewardLoot({ container }) : null,
            incJob ? CharactersActions.incJobXP({ jobCode: incJob }) : null,
          ].filter((action) => action !== null),
        ),
      ),
    ),
  );

  private _rewardXp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardXp),
      filter(({ xp }) => xp > 0),
      withLatestFrom(this._store.select(getPlayer)),
      tap(([{ xp, notification = true }]) => {
        if (notification) {
          this._notificationService.addNotification('item', `+${xp} XP`);
        }
      }),
      switchMap(([{ xp }, player]) => {
        const newXp = player.xp + xp;
        const logAction = ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: player.name,
          text: `a gagné ${xp} points d'expériences`,
          kind: ChatLogKind.Bonus,
        });

        const xpAction =
          newXp >= calculateXpForNextLevel(player.lvl)
            ? CharactersActions.localLevelUp({
                id: player.id,
                kind: player.kind,
                xp,
              })
            : CharactersActions.addXp({ id: player.id, kind: player.kind, xp });

        return [logAction, xpAction];
      }),
    ),
  );

  private _rewardLoot$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.rewardLoot),
      filter(({ container }) => container?.items.length > 0),
      map(({ container }) => {
        const { x, y } = container.position;

        return WorldActions.addContainerTile({
          x,
          y,
          container,
        });
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
