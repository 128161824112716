import { Injectable } from '@angular/core';
import { Character } from '@t12/common/characters/types/character.type';
import { Looking } from '@t12/common/characters/types/looking.type';
import { LoadingStatus } from '@t12/utils/enums/loading-status.enum';
import { UtilsService } from '@t12/utils/services/utils/utils.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { getWorldLoadingStatus } from '@t12/world/store/selector/world.selectors';

@Injectable({
  providedIn: 'root',
})
export class CharacterManagerService {
  constructor(
    private readonly _worldService: WorldGeneratorService,
    private readonly _utils: UtilsService,
  ) {}

  public canMove(character: Character, direction: Looking): boolean {
    const worldIsLoaded = this._utils.getSelect(getWorldLoadingStatus);

    return (
      character.canMove &&
      character.health > 0 &&
      worldIsLoaded === LoadingStatus.LOADED &&
      this._worldService.canStepOnTile({ ...character, looking: direction })
    );
  }

  // Argument : Attaquant et la cible
  // Résultat : Crée un interval de combat pour un NPC
  // public createTurnFightInterval(
  //   attacker: Character,
  //   target: INPC | IMonster,
  // ): void {
  //   const timersFight = this._timerService.getTimersFight();
  //   if (!timersFight.has(target.id) && target.health > 0) {
  //     this._store.dispatch(
  //       CharactersActions.setState({
  //         id: target.id,
  //         kind: target.kind,
  //         state: 'fight',
  //       }),
  //     );
  //
  //     timersFight.set(
  //       target.id,
  //       window.setInterval(() => this._turnFightNPC(target, attacker), 1000),
  //     );
  //   }
  // }
  //
  // // Argument : Personnage, personnage cible
  // // Résultat : Tourne le personnage cible face au personnage
  // public setFaceTo(character1: Character, character2: Character): Looking {
  //   const { position: position1 } = character1;
  //   const { id, kind, position: position2 } = character2;
  //
  //   if (!position1 || !position2) return;
  //
  //   const directions = {
  //     up: position1.y < position2.y,
  //     down: position1.y > position2.y,
  //     left: position1.x < position2.x,
  //     right: position1.x > position2.x,
  //   };
  //
  //   const looking = Object.keys(directions).find(
  //     (key) => directions[key],
  //   ) as Looking;
  //
  //   this._store.dispatch(CharactersActions.setLooking({ id, kind, looking }));
  //   return looking;
  // }
  //
  // // Argument : Attaquant NPC et la cible
  // // Résultat : Prépare le combat et son déroulement
  // private _turnFightNPC(attacker: INPC | IMonster, target: Character): void {
  //   const characters = this._utils.getSelect(getCharacters);
  //   const foundCharacters = [target, attacker].map((obj) =>
  //     characters.find((character) => character.id === obj.id),
  //   );
  //   const foundAttacker = foundCharacters[1];
  //   if (!isNPCOrMonster(foundAttacker)) {
  //     this._timerService.stopTimerFightByID(foundAttacker.id);
  //     return;
  //   }
  //
  //   const foundTarget = foundCharacters[0] as Character; // Assuming target can be any Character
  //   const directionTarget = this.setFaceTo(foundTarget, foundAttacker);
  //   const { x, y } = positionInFrontClose(
  //     foundAttacker.position.x,
  //     foundAttacker.position.y,
  //     foundAttacker.looking,
  //   );
  //   const targetIsAlive = foundTarget.health > 0;
  //   const { position } = foundTarget;
  //   const { id, kind } = foundAttacker;
  //
  //   // Attaque le joueur
  //   if (y === position.y && x === position.x && targetIsAlive) {
  //     this._store.dispatch(
  //       CharactersActions.attack({
  //         id,
  //         characterKind: kind,
  //         attackKind: this._chooseAttackNPC(foundAttacker),
  //       }),
  //     );
  //   } else if (targetIsAlive) {
  //     // Poursuit le joueur
  //     this._store.dispatch(
  //       CharactersActions.move({
  //         id: target.id,
  //         kind: target.kind,
  //         direction: directionTarget,
  //       }),
  //     );
  //   } else if (!targetIsAlive) {
  //     // Joueur/Cible mort
  //     this._store.dispatch(
  //       CharactersActions.setState({
  //         id,
  //         kind,
  //         state: 'passive',
  //       }),
  //     );
  //     this._timerService.stopTimerFightByID(foundAttacker.id);
  //     this.createPatrolInterval(foundAttacker.id);
  //   }
  // }
  //
  // // Argument : Attaquant NPC
  // // Résultat : Choisis le type de la prochaine attaque du NPC, physique ou magique (max 40% chance magic)
  // private _chooseAttackNPC(attacker: INPC | IMonster): AttackKind {
  //   const roll = Math.floor(Math.random() * 100);
  //   const useMagic =
  //     attacker.stats.int > 0 ? Math.min(40, 20 + 0.3 * attacker.stats.int) : -1;
  //
  //   return this.canUseMagic(attacker) && roll <= useMagic ? 'magic' : 'physic';
  // }
  //
  // // Argument : Personnage à vérifier
  // // Résultat : Vérifie que le personnage a assez de mana pour faire une attaque magique.
  // public canUseMagic(character: Character): boolean {
  //   if (!character) return false;
  //
  //
  //   const enoughMana = character.mana >= costManaMagicAttack(character.stats.int);
  //
  //   if (!enoughMana && isPlayer(character) && character.id === 0) {
  //     this._notificationService.addNotification(
  //       'error',
  //       "Vous n'avez pas assez de mana!",
  //     );
  //   }
  //
  //   return enoughMana;
  // }
}
