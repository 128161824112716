import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { BankActions } from '@t12/bank/store/actions/bank.actions';
import { isNPCOrMonster } from '@t12/characters/constants/is-npc-or-monster.constant';
import { isNPC } from '@t12/characters/constants/is-npc.constant';
import { CharacterManagerService } from '@t12/characters/services/character-manager-service/character-manager.service';
import { TimersManagerService } from '@t12/characters/services/timers-bot/timers-manager.service';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getCharacterInFrontOfPlayer,
  getPlayer,
} from '@t12/characters/store/selectors/characters.selectors';
import { DialogDbService } from '@t12/dialog/services/dialog-db/dialog-db.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { areQuestGoalsDone } from '@t12/quest/store/selectors/quest.selectors';
import { ShopActions } from '@t12/shop/store/actions/shop.actions';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';
import {
  withLatestFrom,
  switchMap,
  map,
  take,
  EMPTY,
  filter,
  from,
} from 'rxjs';
import { getEventsProgressStatus } from '../../../events/store/selectors/events.selectors';
import { DialogActions } from '../actions/dialog.actions';
import {
  getActiveTextDialog,
  isLastDialogText,
  getDialogCharacter,
  getDialogOptions,
} from '../selectors/dialog.selectors';

@Injectable()
export class DialogEffects {
  private _startConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.startConversation),
      withLatestFrom(this._store.select(getCharacterInFrontOfPlayer)),
      switchMap(([{ npc }, character]) => {
        if (!isNPCOrMonster(character) || npc.id !== character.id) return EMPTY;

        return this._dialogDb.getDialog(character.dialogCode).pipe(
          take(1),
          filter((textsDialog) => textsDialog.length > 0),
          switchMap((textsDialog) => [
            CharactersActions.setFaceToPlayer({
              id: character.id,
              kind: character.kind,
            }),
            DialogActions.setDialog({ textsDialog }),
          ]),
        );
      }),
    ),
  );

  private _setDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.setDialog, DialogActions.nextMessage),
      withLatestFrom(this._store.select(getDialogOptions)),
      filter(([_, options]) => options && options.length > 0), // Filtrer si options a une longueur supérieure à 0
      switchMap(([_, options]) => {
        const validateOptionIndex = options.findIndex(
          (option) => option.validateQuest,
        );

        if (validateOptionIndex === -1) return [];

        return this._store
          .select(areQuestGoalsDone(options[validateOptionIndex].validateQuest))
          .pipe(
            take(1),
            filter((isDone) => !isDone),
            map(() => {
              const updatedOptions = options.map((option, index) =>
                index === validateOptionIndex
                  ? { ...option, disable: true }
                  : option,
              );
              return DialogActions.setOptionsValidations({
                options: updatedOptions,
              });
            }),
          );
      }),
    ),
  );

  private _continueConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.continueConversation),
      withLatestFrom(
        this._store.select(getActiveTextDialog),
        this._store.select(isLastDialogText),
      ),
      map(([_, textDialog, isLastDialogText]) => {
        if (!textDialog) return null;
        const options = textDialog.options;

        if (options && options.length > 0) {
          for (let index = 0; index < options.length; index++) {
            if (!options[index].disable) {
              return DialogActions.chooseOption({ option: options[index] });
            }
          }
        } else if (!isLastDialogText) {
          return DialogActions.nextMessage();
        } else {
          return DialogActions.endConversation();
        }
      }),
      filter((action) => !!action),
    ),
  );

  private _chooseOption$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.chooseOption),
      withLatestFrom(this._store.select(getDialogCharacter)),
      switchMap(([{ option }, npc]) => {
        if (option.disable) return;
        if (option.label === 'Commerce')
          return [ShopActions.openShop({ npcCode: npc.code })];
        else if (option.label === 'Stockage') return [BankActions.openBank()];
        else if (option.createQuest && isNPC(npc))
          return [
            DialogActions.nextMessageOption({ option }),
            QuestActions.addQuest({
              questCode: option.createQuest,
              npc,
            }),
          ];
        else if (option.validateQuest && isNPC(npc))
          return [
            DialogActions.nextMessageOption({ option }),
            QuestActions.validateQuest({
              questCode: option.validateQuest,
            }),
          ];
        else return [DialogActions.nextMessageOption({ option })];
      }),
      filter((action) => !!action),
    ),
  );

  private _endConversation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.endConversation),
      withLatestFrom(
        this._store.select(getDialogCharacter),
        this._store.select(getPlayer),
        this._store.select(getEventsProgressStatus),
      ),
      switchMap(([_, npc, __, eventStatus]) => {
        const actions: Array<Action> = [
          DialogActions.endConversationSuccess(),
          HudDisplayActions.hideHud({ name: 'dialog' }),
        ];

        if (npc && eventStatus !== ProgressStatus.IN_PROGRESS) {
          const { id, kind, originalLooking } = npc;
          actions.push(
            CharactersActions.setLooking({
              id,
              kind,
              looking: originalLooking,
            }),
          );
        }

        return from(actions); // Utilise `from` pour transformer le tableau d'actions en observable
      }),
    ),
  );

  private _endConversationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.endConversationSuccess),
      map(() => HudDisplayActions.hideHud({ name: 'dialog' })),
    ),
  );

  private _startNarration$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DialogActions.startConversation, DialogActions.startNarration),
      map(() => HudDisplayActions.showHud({ name: 'dialog' })),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _dialogDb: DialogDbService,
    private readonly _characterService: CharacterManagerService,
    private readonly _timerService: TimersManagerService,
    private readonly _store: Store,
  ) {}
}
