import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerID } from '@t12/characters/store/selectors/characters.selectors';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { SocialsDbService } from '../../../services/socials-db/socials-db.service';
import { SocialsActions } from '../../actions/socials.actions';

@Injectable()
export class FriendRequestsEffects {
  private _getFriendRequests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.getFriendRequests),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([, playerId]) =>
        this._socialsDbService.getPlayerFriendRequests(playerId).pipe(
          map((friendRequests) =>
            SocialsActions.setFriendRequests({ friendRequests }),
          ),
          catchError(() => of(SocialsActions.getFriendsFailed())),
        ),
      ),
    ),
  );

  private _updateDisplayedFriendRequests$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        SocialsActions.setFriendRequests,
        SocialsActions.addFriendRequest,
        SocialsActions.removeFriendRequest,
      ),
      map(() => SocialsActions.updateDisplayedFriendRequests()),
    ),
  );

  private _sendFriendRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.sendFriendRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.sendFriendRequest(playerId, name).pipe(
          switchMap((response) => {
            if ('lastConnexion' in response) {
              return [
                SocialsActions.removeFriendRequest({ name: response.name }),
                SocialsActions.addFriend({ playerFriend: response }),
              ];
            } else {
              return of(
                SocialsActions.sendFriendRequestSuccess({
                  name: response.name,
                }),
              );
            }
          }),
          catchError(({ error }: HttpErrorResponse) => {
            const action =
              error?.message === 'NOT_FOUND'
                ? SocialsActions.sendFriendRequestFailedNotFound()
                : error?.message === 'ALREADY_FRIEND'
                  ? SocialsActions.sendFriendRequestFailedAlreadyFriend()
                  : error?.message === 'ALREADY_IN_REQUEST'
                    ? SocialsActions.sendFriendRequestFailedAlreadyRequested()
                    : SocialsActions.sendFriendRequestFailed();
            return of(action);
          }),
        ),
      ),
    ),
  );

  private _sendFriendRequestSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendFriendRequestSuccess),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'settings',
            `Demande d'ami envoyée à ${name}`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendFriendRequestFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          SocialsActions.sendFriendRequestFailed,
          SocialsActions.addFriendFailed,
          SocialsActions.deniedFriendRequestFailed,
        ),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendFriendRequestFailedNotFound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendFriendRequestFailedNotFound),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Aucun joueur trouvé...`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendFriendRequestFailedAlreadyFriend$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendFriendRequestFailedAlreadyFriend),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Vous êtes déjà ami avec ce personnage.`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _sendFriendRequestFailedAlreadyRequested$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.sendFriendRequestFailedAlreadyRequested),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Une demande est déjà en cours.`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _addFriendRequest$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.addFriendRequest),
        tap(({ friendRequest }) => {
          this._notificationService.addNotification(
            'settings',
            `Vous avez reçu une demande d'ami de ${friendRequest.name}`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _acceptFriendRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.acceptFriendRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.acceptFriendRequest(playerId, name).pipe(
          switchMap((playerFriend) => [
            SocialsActions.removeFriendRequest({ name: playerFriend.name }),
            SocialsActions.addFriend({ playerFriend }),
          ]),
          catchError(() => of(SocialsActions.addFriendFailed())),
        ),
      ),
    ),
  );

  private _deniedFriendRequest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.deniedFriendRequest),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ name }, playerId]) =>
        this._socialsDbService.deletePlayerFriendRequest(playerId, name).pipe(
          map(() => SocialsActions.removeFriendRequest({ name })),
          catchError(() => of(SocialsActions.deniedFriendRequestFailed())),
        ),
      ),
    ),
  );

  private _removeFriendRequest$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.removeFriendRequest),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'error',
            `Demande de ${name} refusée`,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socialsDbService: SocialsDbService,
    private readonly _store: Store,
  ) {}
}
