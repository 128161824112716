import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import {
  getPlayer,
  getPlayerName,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatTab } from '@t12/chat/enums/chat-tab.enum';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { getItemUseType } from '@t12/common/item/constants/get-item-use-type.constant';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { ItemUseType } from '@t12/common/item/enums/item-use-type.enum';
import { maxHealth } from '@t12/common/player/constants/max-health.constant';
import { maxMana } from '@t12/common/player/constants/max-mana.constant';
import { EquipmentsActions } from '@t12/equipments/store/actions/equipments.actions';
import { InventoryDbService } from '@t12/inventory/services/inventory-db/inventory-db.service';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { getPlayerItemInventory } from '@t12/inventory/store/selectors/inventory.selectors';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { labelInfos } from '@t12/player/constants/player-infos';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import {
  filter,
  forkJoin,
  map,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class InventoryUseEffects {
  private _useItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.useItem),
      throttleTime(200),
      switchMap((action) =>
        forkJoin({
          player: this._store.select(getPlayer).pipe(take(1)),
          item: this._store
            .select(getPlayerItemInventory(action.itemCode))
            .pipe(take(1)),
        }).pipe(
          map(({ player, item }) => ({
            action,
            player,
            item,
          })),
        ),
      ),
      filter(
        ({ item }) =>
          item.amount > 0 && (!!item.use || (!!item.slot && !!item.stats)),
      ),
      map(({ item, player }) => {
        if (player.health <= 0)
          return InventoryActions.useItemFailed({
            message: 'Vous êtes mort...',
          });
        if (!item)
          return InventoryActions.useItemFailed({
            message: 'Vous ne possédez pas ça sur vous!',
          });
        if (item.lvl > player.lvl)
          return InventoryActions.useItemFailed({
            message: "Vous n'avez pas le niveau requis!",
          });

        if (item.use?.infos) {
          return InventoryActions.consumeItem({ character: player, item });
        } else if (item.use?.warp) {
          return InventoryActions.consumeItemWarp({
            id: player.id,
            kind: player.kind,
            item,
          });
        } else if (item.stats && item.slot) {
          return EquipmentsActions.equipItem({ item });
        } else if (item.use?.learn) {
          return JobActions.learnKnowledge({ item });
        } else return null;
      }),
      filter((action) => !!action),
    ),
  );

  private _useItemFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.useItemFailed),
        tap(({ message }) => {
          this._notificationService.addNotification('error', message);
        }),
      ),
    { dispatch: false },
  );

  private _consumeItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItem),
      map(({ item, character }) => {
        const itemUseType = getItemUseType(item);
        const isFullHealth =
          character.health === maxHealth(character.stats.con);
        const isFullMana = character.mana === maxMana(character.stats.int);
        const { id } = character;
        switch (itemUseType) {
          case ItemUseType.HEALTH:
            if (isFullHealth) return InventoryActions.consumeItemHealthFail();
            else
              return InventoryActions.consumeItemHealth({
                id,
                kind: CharacterKind.PLAYER,
                item,
              });

          case ItemUseType.MANA:
            if (isFullMana) return InventoryActions.consumeItemManaFail();
            else
              return InventoryActions.consumeItemMana({
                id,
                kind: CharacterKind.PLAYER,
                item,
              });

          case ItemUseType.HEALTH_MANA:
            if (isFullHealth && isFullMana)
              return InventoryActions.consumeItemHealthAndManaFail();
            else
              return InventoryActions.consumeItemHealthAndMana({
                id,
                kind: CharacterKind.PLAYER,
                item,
              });

          default:
            return null;
        }
      }),
      filter((action) => !!action),
    ),
  );

  private _consumeItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        InventoryActions.consumeItemHealth,
        InventoryActions.consumeItemMana,
        InventoryActions.consumeItemHealthAndMana,
      ),
      withLatestFrom(this._store.select(getPlayerName)),
      switchMap(([{ item }, playerName]) =>
        this._inventoryDbService.useItem(item.code).pipe(
          take(1),
          switchMap(() => [
            ChatActions.addChatLog({
              tab: ChatTab.ACTION,
              name: playerName,
              text: `a utilisé l'objet "${item.name}".`,
              kind: ChatLogKind.Bonus,
            }),
            InventoryActions.removeItemInInventory({
              itemCode: item.code,
              amount: 1,
            }),
          ]),
        ),
      ),
    ),
  );

  private _consumeItemNotification$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          InventoryActions.consumeItemHealth,
          InventoryActions.consumeItemMana,
          InventoryActions.consumeItemHealthAndMana,
        ),
        tap(({ item }) => {
          const text = item.use.infos
            .map((useInfo) => `+${useInfo.value} ${labelInfos[useInfo.stat]}`)
            .join(' ');
          this._notificationService.addNotification(
            'item',
            text,
            3000,
            item.img,
          );
        }),
      ),
    { dispatch: false },
  );

  private _consumeItemSound$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          InventoryActions.consumeItemHealth,
          InventoryActions.consumeItemMana,
          InventoryActions.consumeItemHealthAndMana,
        ),
        tap(({ item }) => {
          const soundMap = {
            [ItemType.Potion]: `gulp_${Math.floor(Math.random() * 2)}`,
            [ItemType.Food]: `munch_${Math.floor(Math.random() * 4)}`,
          };

          const sound = soundMap[item.type];
          if (sound) {
            this._audioService.playSound('miscs', sound, 'wav', 0.35);
          }
        }),
      ),
    { dispatch: false },
  );

  private _consumeItemHealth$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItemHealth),
      map(({ item, id, kind }) => {
        return CharactersActions.addHealth({
          id,
          kind,
          health: item.use.infos[0].value,
        });
      }),
    ),
  );

  private _consumeItemMana$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItemMana),
      map(({ item, id, kind }) => {
        return CharactersActions.addMana({
          id,
          kind,
          mana: item.use.infos[0].value,
        });
      }),
    ),
  );

  private _consumeItemHealthAndMana$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItemHealthAndMana),
      map(({ item, id, kind }) => {
        return CharactersActions.addHealthMana({
          id,
          kind,
          health: item.use.infos[0].value,
          mana: item.use.infos[1].value,
        });
      }),
    ),
  );

  private _consumeItemHealthFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.consumeItemHealthFail),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Votre vie est déjà au maximum.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _consumeItemManaFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.consumeItemManaFail),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Votre mana est déjà au maximum.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _consumeItemHealthAndManaFail$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(InventoryActions.consumeItemHealthAndManaFail),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Votre vie et mana sont déjà au maximum!',
          );
        }),
      ),
    { dispatch: false },
  );

  private _consumeItemWarp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(InventoryActions.consumeItemWarp),
      tap(() => {
        this._audioService.playSound('miscs', `teleport_0`, 'wav', 0.5);
      }),
      switchMap(({ item }) => [
        InventoryActions.removeItemInInventory({
          itemCode: item.code,
          amount: 1,
        }),
        WorldActions.teleportTo({ scroll: item.code }),
      ]),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _inventoryDbService: InventoryDbService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _store: Store,
  ) {}
}
