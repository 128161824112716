import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CharactersActions } from '@t12/characters/store/actions/characters.actions';
import { getPlayer } from '@t12/characters/store/selectors/characters.selectors';
import { ChatTab } from '@t12/chat/enums/chat-tab.enum';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { deathPenaltyGold } from '@t12/common/player/constants/death-penalty-gold.constant';
import { deathPenaltyXp } from '@t12/common/player/constants/death-penalty-xp.constant';
import { InventoryActions } from '@t12/inventory/store/actions/inventory.actions';
import { HotkeyActions } from '@t12/key-commands/store/hotkey/actions/hotkey.actions';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { delayRespawnPlayer } from '@t12/player/constants/delay-respawn-player.constant';
import { PlayerDbService } from '@t12/player/services/player-db/player-db.service';
import { PlayerSocketService } from '@t12/player/services/player-socket/player-socket.service';
import { QuestDbService } from '@t12/quest/services/quest-db/quest-db.service';
import { QuestActions } from '@t12/quest/store/actions/quest.actions';
import { AudioManagerService } from '@t12/settings/services/audio/audio-manager.service';
import { SocialsActions } from '@t12/socials/store/actions/socials.actions';
import { UserActions } from '@t12/user/store/actions/user.actions';
import { ProgressStatus } from '@t12/utils/enums/progress-status.enum';
import { SocketService } from '@t12/utils/services/socket/socket.service';
import { WorldGeneratorService } from '@t12/world/services/world-generator/world-generator.service';
import { WorldActions } from '@t12/world/store/actions/world-actions';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import {
  catchError,
  delay,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { getEventsProgressStatus } from '../../../../events/store/selectors/events.selectors';

dayjs.locale('fr');

@Injectable()
export class PlayerCharacterEffects {
  private _loadPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.loadPlayer),
      switchMap(() =>
        this._playerDbService.getPlayerCharacter$().pipe(
          take(1),
          switchMap((player) => [
            HotkeyActions.resetHotkeys(),
            CharactersActions.loadPlayerSuccess({ player }),
          ]),
          catchError((error) =>
            of(CharactersActions.loadPlayerFailed({ error })),
          ),
        ),
      ),
    ),
  );

  private _loadPlayerSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.loadPlayerSuccess),
      withLatestFrom(this._store.select(getEventsProgressStatus)),
      switchMap(([{ player }, eventStatus]) => {
        const lastConnexionDate = dayjs(player.lastConnexion ?? new Date());
        const formattedDate = `Dernière connexion à ${lastConnexionDate.format('HH:mm')}, le ${lastConnexionDate.format('DD MMMM YYYY')}.`;

        this._socketService.initSocket(player.id);

        if (!player.lastConnexion && eventStatus !== ProgressStatus.DONE) {
          this._store.dispatch(
            HudDisplayActions.showHud({ name: 'presentation' }),
          );
        }

        return [
          ChatActions.addChatLog({
            tab: ChatTab.CHAT,
            name: '',
            text: formattedDate,
            kind: ChatLogKind.Log,
          }),
          ChatActions.addChatLog({
            tab: ChatTab.CHAT,
            name: '',
            text: '/say ou /s pour parler en local',
            kind: ChatLogKind.Log,
          }),
          ChatActions.addChatLog({
            tab: ChatTab.CHAT,
            name: '',
            text: '/all ou /a pour parler en global',
            kind: ChatLogKind.Log,
          }),
          CharactersActions.initPlayerSuccess(),
          SocialsActions.getFriends(),
          SocialsActions.getFriendRequests(),
          SocialsActions.getGroup(),
          SocialsActions.getGroupRequests(),
          WorldActions.loadWorld(),
          InventoryActions.setInventory({ items: player.inventory }),
        ];
      }),
    ),
  );

  // TODO Optimisez en le faisant uniquement quand un autoValidate est fait côté back pour éviter call à chaque map
  private _getQuestsPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(WorldActions.loadWorldSuccess),
      switchMap(() =>
        this._questDbService.getQuestsPlayer().pipe(
          take(1),
          map((questsInfos) => QuestActions.setQuests({ questsInfos })),
          catchError(() => of(QuestActions.setQuestsFailed())),
        ),
      ),
    ),
  );

  private _levelUp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.localLevelUp),
      withLatestFrom(this._store.select(getPlayer)),
      tap(([_, player]) => {
        this._audioService.playSound('miscs', 'lvlup', 'wav');
        this._notificationService.addNotification(
          'item',
          `Vous venez de gagner un niveau ! (Lvl ${player.lvl})`,
        );
      }),
      map(([_, player]) =>
        ChatActions.addChatLog({
          tab: ChatTab.FIGHT,
          name: player.name,
          text: `a gagné un niveau ! (Lvl ${player.lvl})`,
          kind: ChatLogKind.Bonus,
        }),
      ),
    ),
  );

  private _playerDeath$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.playerDeath),
      withLatestFrom(this._store.select(getPlayer)),
      delay(delayRespawnPlayer),
      switchMap(([_, player]) =>
        this._playerDbService.playerDeath().pipe(
          take(1),
          tap((worldPosition) => {
            this._playerSocketService.playerLeaveWorldAction(
              player.id,
              worldPosition.worldCode,
              worldPosition.position,
            );
          }),
          mergeMap((worldPosition) => [
            ChatActions.addChatLog({
              tab: ChatTab.FIGHT,
              name: player.name,
              text: `est mort et a perdu ${Math.ceil(
                player.gold / deathPenaltyGold,
              )} OR et ${Math.ceil(player.xp / deathPenaltyXp)} points d'expériences`,
              kind: ChatLogKind.Malus,
            }),
            CharactersActions.applyDeathSanction({
              id: player.id,
              worldPosition,
            }),
            WorldActions.loadWorld(),
          ]),
          catchError((error) =>
            of(UserActions.registerLoginUserFail({ error })),
          ),
        ),
      ),
    ),
  );

  // TODO Ajouter la fusion de slots côté back
  private _orderInventory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        InventoryActions.addItemInInventory,
        InventoryActions.removeItemInInventory,
        InventoryActions.setInventory,
      ),
      map(() => InventoryActions.orderInventory()),
    ),
  );

  private _addPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CharactersActions.addPlayer),
      switchMap(({ id }) =>
        this._playerDbService.getMinimalPlayerCharacter(id).pipe(
          take(1),
          map((player) => CharactersActions.addPlayerSuccess({ player })),
          catchError(() => of(CharactersActions.addPlayerFailed())),
        ),
      ),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _audioService: AudioManagerService,
    private readonly _notificationService: NotificationManagerService,
    private readonly _playerDbService: PlayerDbService,
    private readonly _playerSocketService: PlayerSocketService,
    private readonly _questDbService: QuestDbService,
    private readonly _socketService: SocketService,
    private readonly _store: Store,
    private readonly _worldService: WorldGeneratorService,
  ) {}
}
