import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { getPmPlayerName } from '@t12/chat/store/selectors/chat.selectors';
import { allChatCommands } from '@t12/common/chat/constants/chat-commands.const';
import { ChatLogColor } from '@t12/common/chat/enums/chat-log-color.enums';
import { ChatLogKind } from '@t12/common/chat/enums/chat-log-kind.enums';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';

import { ChatLogLayout } from '@t12/common/chat/interfaces/chat-log-layout.interface';
import { ChatLog } from '@t12/common/chat/interfaces/chat-log.interface';
import { PlayerFriend } from '@t12/common/socials/interfaces/player-friend.interface';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { getFriends } from '@t12/socials/store/selectors/socials.selectors';
import { UtilsService } from '@t12/utils/services/utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ChatManagerService {
  constructor(
    private readonly _store: Store,
    private readonly _notificationService: NotificationManagerService,
    private readonly _utils: UtilsService,
  ) {}

  public initChatLog(
    name: string,
    scope: ChatLogScope,
    text: string,
    kindOverride: ChatLogKind,
    timeDate?: string,
  ): ChatLog {
    const kind = kindOverride
      ? kindOverride
      : this.getChatLogKindCmd(text, scope);
    if (kind === ChatLogKind.Unknown) return undefined;

    const layout = this.setChatLayout(kind);
    const time =
      timeDate ??
      new Date().toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
      });

    let chatLog: ChatLog = {
      name,
      scope,
      layout,
      text,
      time,
      kind,
    };

    if (allChatCommands[kind] || scope !== ChatLogScope.Local)
      chatLog = this.executeCommand(chatLog);
    else if (
      chatLog.kind !== ChatLogKind.Bonus &&
      chatLog.kind !== ChatLogKind.Malus
    ) {
      chatLog.text = this.cleanFormatText(text);
    }

    return chatLog;
  }

  public setChatLayout(chatLogKind: ChatLogKind): ChatLogLayout {
    const layout: ChatLogLayout = {
      nameColor: ChatLogColor.Action,
      textColor: ChatLogColor.Default,
    };

    switch (chatLogKind) {
      case ChatLogKind.Log:
        layout.textColor = ChatLogColor.Log;
        break;
      case ChatLogKind.Roll:
        layout.textColor = ChatLogColor.Roll;
        break;
      case ChatLogKind.Action:
        layout.textColor = ChatLogColor.Action;
        break;
      case ChatLogKind.Bonus:
        layout.nameColor = ChatLogColor.Bonus;
        break;
      case ChatLogKind.Malus:
        layout.nameColor = ChatLogColor.Malus;
        break;
      case ChatLogKind.Pm:
        layout.nameColor = ChatLogColor.Pm;
        layout.textColor = ChatLogColor.Pm;
        break;
      case ChatLogKind.Party:
        layout.nameColor = ChatLogColor.Party;
        break;
    }

    return layout;
  }

  // Argument : ChatLog complet
  // Résultat : Vérifie si une commande a été saisie
  public getChatLogKindCmd(text: string, scope: ChatLogScope): ChatLogKind {
    if (text.charAt(0) === '/') {
      const cmd = text.split('/').pop().split(' ')[0].toLowerCase();
      const foundKey = Object.keys(allChatCommands).find((key) =>
        allChatCommands[key].includes(cmd),
      );

      if (foundKey) {
        return foundKey as ChatLogKind;
      } else {
        this._notificationService.addNotification(
          'error',
          "Cette commande n'existe pas.",
        );
        return ChatLogKind.Unknown;
      }
    } else if (scope === ChatLogScope.Global) return ChatLogKind.All;
    else if (scope === ChatLogScope.Pm) return ChatLogKind.Pm;
    else if (scope === ChatLogScope.Party) return ChatLogKind.Party;
    else return ChatLogKind.Talk;
  }

  // Argument : ChatLog complet
  // Résultat : Exécute la commande présente dans le chat.type
  public executeCommand(chat: ChatLog): ChatLog {
    switch (chat.kind) {
      case ChatLogKind.Roll:
        return this._rollCommand(chat);
      case ChatLogKind.Action:
        return this._meCommand(chat);
      case ChatLogKind.All:
        return this._allCommand(chat);
      case ChatLogKind.Talk:
        return this._sayCommand(chat);
      case ChatLogKind.Pm:
        return this._mpCommand(chat);
      case ChatLogKind.Party:
        return this._partyCommand(chat);
      default:
        break;
    }
  }

  // Argument : ChatLog contenant un maximum facultatif pour limiter le roll
  // Résultat : Exécute la commande de roll pour jeter un dé de 100 par défaut
  private _rollCommand(chat: ChatLog): ChatLog {
    this._store.dispatch(
      ChatActions.setScopeChat({ scopeChat: ChatLogScope.Local }),
    );
    const lengthCmd = chat.text.split('/').pop().split(' ')[0].length + 1;
    const max = parseInt(chat.text.substring(lengthCmd), 10) || 100;
    const rand = Math.floor(Math.random() * max);
    chat.text = ` a fait un roll de ${rand} sur ${max}.`;
    chat.layout.textColor = ChatLogColor.Roll;

    return chat;
  }

  // Argument : ChatLog contenant le texte descriptif de l'action
  // Résultat : Exécute la commande pour décrire une action d'un personnage
  private _meCommand(chat: ChatLog): ChatLog {
    this._store.dispatch(
      ChatActions.setScopeChat({ scopeChat: ChatLogScope.Local }),
    );
    const lengthCmd = chat.text.split('/').pop().split(' ')[0].length + 1;
    chat.text = this.cleanFormatText(chat.text.substring(lengthCmd), false);
    chat.layout = {
      nameColor: ChatLogColor.Action,
      textColor: ChatLogColor.Action,
    };

    return chat;
  }

  // Argument : ChatLog global
  // Résultat : Exécute la commande pour envoyer un message global
  private _allCommand(chat: ChatLog): ChatLog {
    if (chat.text.startsWith('/')) {
      this._store.dispatch(
        ChatActions.setScopeChat({ scopeChat: ChatLogScope.Global }),
      );
      const lengthCmd = chat.text.split(' ')[0].length;
      chat.text = chat.text.substring(lengthCmd).trim();
    }
    chat.name = `[Global] ${chat.name}`;
    chat.text = this.cleanFormatText(chat.text);
    return chat;
  }

  private _sayCommand(chat: ChatLog): ChatLog {
    if (chat.text.startsWith('/')) {
      this._store.dispatch(
        ChatActions.setScopeChat({ scopeChat: ChatLogScope.Local }),
      );
      const lengthCmd = chat.text.split(' ')[0].length;
      chat.text = chat.text.substring(lengthCmd).trim();
    }
    chat.text = this.cleanFormatText(chat.text);

    return chat;
  }

  private _mpCommand(chat: ChatLog): ChatLog {
    let friendFound: PlayerFriend;
    if (chat.text.startsWith('/')) {
      const playerName = chat.text.split(' ')[1];
      if (!playerName) {
        this._notificationService.addNotification(
          'error',
          'Merci de renseigner le nom du joueur',
        );
        return undefined;
      }

      const friends = this._utils.getSelect(getFriends);
      friendFound = friends.find((friend) =>
        new RegExp(playerName, 'i').test(friend.name),
      );

      if (!friendFound) {
        this._notificationService.addNotification(
          'error',
          "Vous n'avez pas d'amis qui match avec ce nom",
        );
        return undefined;
      }

      const text = chat.text.split(' ')[2];
      if (!text) {
        this._notificationService.addNotification(
          'error',
          "Merci d'écrire un message à envoyer",
        );
        return undefined;
      }

      this._store.dispatch(
        ChatActions.setPmPlayer({ name: friendFound?.name }),
      );

      chat.text = text.trim();
    }
    const isReceiver = !this._utils.getSelect(getPmPlayerName) && !friendFound;

    chat.name = `[${isReceiver ? 'De' : 'À'} ${this._utils.getSelect(getPmPlayerName) || friendFound?.name || chat.name}]`;
    chat.layout = {
      nameColor: ChatLogColor.Pm,
      textColor: ChatLogColor.Pm,
    };
    chat.text = this.cleanFormatText(chat.text);

    return chat;
  }

  private _partyCommand(chat: ChatLog) {
    if (chat.text.startsWith('/')) {
      this._store.dispatch(
        ChatActions.setScopeChat({ scopeChat: ChatLogScope.Party }),
      );
      const lengthCmd = chat.text.split(' ')[0].length;
      chat.text = chat.text.substring(lengthCmd).trim();
    }
    chat.name = `[Groupe] ${chat.name}`;
    chat.text = this.cleanFormatText(chat.text);
    return chat;
  }

  // Argument : Texte à formater
  // Résultat : Renvoie la phrase en ajoutant une majuscule, un point en fin de phrase et les espaces en trop automatiquement
  public cleanFormatText(textChat: string, uppercase: boolean = true): string {
    let formatTextChat = uppercase
      ? textChat.charAt(0).toUpperCase() + textChat.slice(1)
      : textChat;
    const lastCharacter = formatTextChat.charAt(formatTextChat.length - 1);
    const endCharacter = ['?', '!', '.'];

    if (!endCharacter.includes(lastCharacter)) {
      formatTextChat = `${formatTextChat}.`;
    }

    return formatTextChat.split(/\s+/).join(' ');
  }
}
