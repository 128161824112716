import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { BankEffects } from '@t12/bank/store/effects/bank/bank.effects';
import { BankDepositFromEffects } from '@t12/bank/store/effects/deposit-from/bank-deposit-from.effects';
import { BankDepositToEffects } from '@t12/bank/store/effects/deposit-to/bank-deposit-to.effects';
import { AttackCharacterEffects } from '@t12/characters/store/effects/attack/attack-character.effects';
import { CharactersEffects } from '@t12/characters/store/effects/character/characters.effects';
import { FightCharacterEffects } from '@t12/characters/store/effects/fight/fight-character.effects';
import { MoveCharacterEffects } from '@t12/characters/store/effects/move/move-character.effect';
import { PlayerCharacterEffects } from '@t12/characters/store/effects/player/player-character.effects';
import { ChatEffects } from '@t12/chat/store/effects/chat.effects';
import { ContainerEffects } from '@t12/container/store/effects/container.effects';
import { CreationPlayerEffects } from '@t12/creation-player/store/effects/creation-player.effects';
import { DialogEffects } from '@t12/dialog/store/effects/dialog.effects';
import { EquipmentsEffects } from '@t12/equipments/store/effects/equipments.effects';
import { InventoryDropEffects } from '@t12/inventory/store/effects/inventory-drop/inventory-drop.effects';
import { InventoryFavoriteEffects } from '@t12/inventory/store/effects/inventory-favorite/inventory-favorite.effects';
import { InventoryPickEffects } from '@t12/inventory/store/effects/inventory-pick/inventory-pick.effects';
import { InventoryUseEffects } from '@t12/inventory/store/effects/inventory-use/inventory-use.effects';
import { HarvestEffects } from '@t12/jobs/store/effects/harvest/harvest.effects';
import { JobsEffects } from '@t12/jobs/store/effects/job/jobs.effects';
import { KnowledgeEffects } from '@t12/jobs/store/effects/knowledge/knowledge.effects';
import { CraftRecipeEffects } from '@t12/jobs/store/effects/recipe/craft-recipes.effects';
import { ListRecipesEffects } from '@t12/jobs/store/effects/recipe/list-recipes.effects';
import { RecipeEffects } from '@t12/jobs/store/effects/recipe/recipe.effects';
import { WorkshopEffects } from '@t12/jobs/store/effects/workshop/workshop.effects';
import { HotkeyEffects } from '@t12/key-commands/store/hotkey/effects/hotkey.effects';
import { HudDisplayEffects } from '@t12/overlay/store/effects/hud-display.effects';
import { InteractionEffects } from '@t12/player/store/effects/interaction.effects';
import { QuestEffects } from '@t12/quest/store/effects/quest.effects';
import { ShopBuyEffects } from '@t12/shop/store/effects/shop-buy/shop-buy.effects';
import { ShopSellEffects } from '@t12/shop/store/effects/shop-sell/shop-sell.effects';
import { ShopEffects } from '@t12/shop/store/effects/shop/shop.effects';
import { GroupRequestsEffects } from '@t12/socials/store/effects/group/group-requests.effects';
import { GroupEffects } from '@t12/socials/store/effects/group/group.effects';
import { PmEffects } from '@t12/socials/store/effects/pm/pm.effects';
import { UserEffects } from '@t12/user/store/effects/user.effects';
import { WorldEffects } from '@t12/world/store/effects/world.effects';
import { routes } from './app-routing.module';
import { EventsEffects } from './events/store/effects/events.effects';
import { FriendRequestsEffects } from './socials/store/effects/friends/friend-requests.effects';
import { FriendsEffects } from './socials/store/effects/friends/friends.effects';
import { PlayerLoggedEffects } from './socials/store/effects/player-logged/player-logged.effects.service';
import { storeOptions, storeReducers } from './store-config';
import { TranslocoHttpLoader } from './transloco-loader';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    provideStore(storeReducers, storeOptions),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      connectInZone: true,
    }),
    provideEffects([
      UserEffects,
      CharactersEffects,
      MoveCharacterEffects,
      AttackCharacterEffects,
      FightCharacterEffects,
      PlayerCharacterEffects,
      HudDisplayEffects,
      DialogEffects,
      CreationPlayerEffects,
      QuestEffects,
      WorldEffects,
      InteractionEffects,
      InventoryPickEffects,
      InventoryDropEffects,
      InventoryUseEffects,
      InventoryFavoriteEffects,
      HotkeyEffects,
      EquipmentsEffects,
      ContainerEffects,
      KnowledgeEffects,
      JobsEffects,
      RecipeEffects,
      ListRecipesEffects,
      CraftRecipeEffects,
      HarvestEffects,
      WorkshopEffects,
      ShopEffects,
      ShopBuyEffects,
      ShopSellEffects,
      BankEffects,
      BankDepositToEffects,
      BankDepositFromEffects,
      EventsEffects,
      ChatEffects,
      FriendsEffects,
      FriendRequestsEffects,
      PmEffects,
      PlayerLoggedEffects,
      GroupRequestsEffects,
      GroupEffects,
    ]),
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['fr', 'en'],
        defaultLang: 'fr',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
};
