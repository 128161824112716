import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getPlayerJobs } from '@t12/characters/store/selectors/characters.selectors';
import { JobKind } from '@t12/common/job/enums/job-kind.enum';
import { maxCraftsmanship } from '@t12/common/job/max-craftmanship.constant';
import { getKindJobByCode } from '@t12/jobs/constants/get-job-kind-by-code.constant';
import { isRecipe } from '@t12/jobs/constants/is-recipe.constant';
import { isWisdomJob } from '@t12/jobs/constants/is-wisdom-job.constant';
import { JobActions } from '@t12/jobs/store/actions/job.actions';
import { map, filter, withLatestFrom } from 'rxjs';

@Injectable()
export class KnowledgeEffects {
  private _learnKnowledge$ = createEffect(() =>
    this._actions$.pipe(
      ofType(JobActions.learnKnowledge),
      filter(({ item }) => !!item.use?.learn),
      withLatestFrom(this._store.select(getPlayerJobs)),
      map(([{ item }, playerJobs]) => {
        const { learn } = item.use;

        if (isWisdomJob(learn)) {
          const hasJob = playerJobs[JobKind.Craftsmanship].some(
            (job) => job.code === learn.code,
          );

          return hasJob
            ? JobActions.learnJobFailedAlreadyKnow()
            : learn.kind === JobKind.Craftsmanship &&
                playerJobs[JobKind.Craftsmanship].length >= maxCraftsmanship
              ? JobActions.learnJobFailedReachedMax()
              : JobActions.learnJob({ itemCode: item.code });
        }

        if (isRecipe(learn)) {
          const kindJob = getKindJobByCode(learn.jobCode);
          const playerJob = playerJobs[kindJob]?.find(
            (job) => job.code === learn.jobCode,
          );
          return !playerJob
            ? JobActions.learnRecipeFailMissingJob()
            : playerJob.xp < learn.requiredXp
              ? JobActions.learnRecipeFailNotEnoughXp({
                  playerJobXp: playerJob.xp,
                  requiredXp: learn.requiredXp,
                })
              : JobActions.learnRecipe({ item: item });
        }

        return null;
      }),
      filter((action) => !!action),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
