import { createReducer, on } from '@ngrx/store';
import { bankSortItems } from '@t12/common/bank/sort-items/bank-sort-items.function';
import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { ItemType } from '@t12/common/item/enums/item-type.enum';
import { nbItemsPageShop } from '@t12/shop/constants/nb-items-page-shop.constant';
import { shopState, ShopState } from '@t12/shop/store';
import { ShopActions } from '../actions/shop.actions';

export const ShopReducer = createReducer(
  shopState,

  on(ShopActions.init, () => shopState),

  on(ShopActions.openShopSuccess, (shopState: ShopState, { shops }) => {
    const filters = Object.entries(shops)
      .filter(([_, items]) => items.length > 0)
      .map(([key]) => key) as (EquipmentSlot | ItemType)[];

    return {
      ...shopState,
      shops,
      filters,
      activeFilter: filters[0],
    };
  }),

  on(ShopActions.removeItemFromShop, (shopState: ShopState, { itemCode }) => {
    const recipes = shopState.shops.recipe.filter(
      (item) => item.code !== itemCode,
    );
    const activeFilter = shopState.filters.find(
      (filter) => filter !== ItemType.Recipe,
    );
    const filters = shopState.filters.filter(
      (filter) => filter !== ItemType.Recipe,
    );
    return {
      ...shopState,
      shops: {
        ...shopState.shops,
        recipe: recipes.length > 0 ? recipes : undefined,
      },
      activeFilter: recipes.length > 0 ? shopState.activeFilter : activeFilter,
      filters: recipes.length > 0 ? shopState.filters : filters,
    };
  }),

  on(ShopActions.setActiveItem, (shopState: ShopState, { item }) => ({
    ...shopState,
    activeItem: shopState.activeItem?.code !== item.code ? item : undefined,
  })),

  on(ShopActions.resetActiveItem, (shopState: ShopState) => ({
    ...shopState,
    activeItem: undefined,
  })),

  on(
    ShopActions.updateActiveItemAmount,
    (shopState: ShopState, { amount }) => ({
      ...shopState,
      activeItemAmount: amount,
    }),
  ),

  on(ShopActions.setFilter, (shopState: ShopState, { filter }) => ({
    ...shopState,
    activeFilter: filter,
    currentPage: 1,
    activeItem: undefined,
    activeItemAmount: 0,
  })),

  on(ShopActions.incCurrentPage, (shopState: ShopState, { inc }) => {
    const newPage = Math.min(
      Math.max(shopState.currentPage + inc, 1),
      shopState.maxPage,
    );

    return {
      ...shopState,
      currentPage: newPage,
      activeItem: undefined,
      activeItemAmount: 0,
    };
  }),

  on(ShopActions.setPage, (shopState: ShopState, { page }) => {
    const newPage = Math.min(Math.max(page, 1), shopState.maxPage);

    return {
      ...shopState,
      currentPage: newPage,
      activeItem: undefined,
      activeItemAmount: 0,
    };
  }),

  on(ShopActions.updateItemsDisplayed, (shopState: ShopState) => {
    const shop = shopState.shops[shopState.activeFilter];
    if (!shop) return shopState;
    const currentPage = Math.min(shopState.currentPage, shop.length);
    const startIndex = (currentPage - 1) * nbItemsPageShop;

    return {
      ...shopState,
      displayedItems: shop
        .slice(startIndex, startIndex + nbItemsPageShop)
        .sort(bankSortItems),
      maxPage: Math.ceil(shop.length / nbItemsPageShop),
      currentPage,
    };
  }),

  on(ShopActions.closeShop, (_: ShopState) => ({
    currentPage: 1,
  })),
);
