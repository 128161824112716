import { Injectable } from '@angular/core';
import { Position } from '@t12/common/characters/interfaces/position.interface';
import { Looking } from '@t12/common/characters/types/looking.type';
import { PlayerLookingActionSocket } from '@t12/common/player/constants/player-looking-action-socket.interface';
import { PlayerMoveActionSocket } from '@t12/common/player/constants/player-move-action-socket.interface';
import { SocketService } from '@t12/utils/services/socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerSocketService {
  constructor(private readonly _socketService: SocketService) {}

  public movePlayerAction(id: number, direction: Looking) {
    const playerEmit: PlayerMoveActionSocket = {
      id,
      direction,
    };

    this._socketService.emit('player-move', playerEmit);
  }

  public lookingPlayerAction(id: number, looking: Looking) {
    const playerEmit: PlayerLookingActionSocket = {
      id,
      looking,
    };

    this._socketService.emit('player-looking', playerEmit);
  }

  public playerLeaveWorldAction(
    id: number,
    worldCode: string,
    position: Position,
  ) {
    const playerEmit = { id, worldCode, position };

    this._socketService.emit('player-leave-world', playerEmit);
  }
}
