import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getPlayerID,
  getPlayerName,
} from '@t12/characters/store/selectors/characters.selectors';
import { ChatActions } from '@t12/chat/store/actions/chat.actions';
import { getScopeChat } from '@t12/chat/store/selectors/chat.selectors';
import { ChatLogScope } from '@t12/common/chat/enums/chat-log-scope.enums';
import { NotificationManagerService } from '@t12/overlay/services/notification/notification-manager.service';
import { HudDisplayActions } from '@t12/overlay/store/actions/hud-display.actions';
import { getHudSocials } from '@t12/overlay/store/selectors/hud-display.selectors';
import { SocialTab } from '@t12/socials/enums/social-tab.enum';
import {
  getSocialsActiveTab,
  getPlayersGroupSize,
} from '@t12/socials/store/selectors/socials.selectors';
import {
  catchError,
  EMPTY,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
  take,
  filter,
} from 'rxjs';
import { SocialsDbService } from '../../../services/socials-db/socials-db.service';
import { SocialsActions } from '../../actions/socials.actions';

@Injectable()
export class GroupEffects {
  private _showHudSocials$ = createEffect(() =>
    this._actions$.pipe(
      ofType(HudDisplayActions.showHud, HudDisplayActions.toggleHud),
      withLatestFrom(
        this._store.select(getHudSocials),
        this._store.select(getSocialsActiveTab),
      ),
      switchMap(([{ name }, hudSocials, tab]) =>
        name === 'socials' && hudSocials && tab === SocialTab.Group
          ? [SocialsActions.getGroupRequests()]
          : EMPTY,
      ),
    ),
  );

  private _getGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.getGroup),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._socialsDbService.getPlayerGroup(playerId).pipe(
          take(1),
          map((group) => SocialsActions.setGroup({ group })),
          catchError(({ error }: HttpErrorResponse) => {
            const action =
              error?.message === 'NOT_IN_GROUP'
                ? SocialsActions.sendGroupRequestFailedNotLeader()
                : error?.message === 'NOT_LEADER_GROUP'
                  ? SocialsActions.sendGroupRequestFailedNotFound()
                  : error?.message === 'ALREADY_LEADER_GROUP'
                    ? SocialsActions.sendGroupRequestFailedAlreadyInGroup()
                    : error?.message === 'MAX_SIZE_GROUP'
                      ? SocialsActions.sendGroupRequestFailedMaxSizeGroup()
                      : error?.message === 'ALREADY_REQUESTED'
                        ? SocialsActions.sendGroupRequestFailedAlreadyRequested()
                        : SocialsActions.sendGroupRequestFailed();
            return of(action);
          }),
        ),
      ),
    ),
  );

  private _getGroupFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.getGroupFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Impossible de récupérer les infos de votre groupe.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _setGroupLeader$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.setGroupLeader),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([{ leaderName }, playerId]) =>
        this._socialsDbService.changeGroupLeader(playerId, leaderName).pipe(
          take(1),
          map((leader) => SocialsActions.setGroupLeaderSuccess({ leader })),
          catchError(({ error }: HttpErrorResponse) => {
            const action =
              error?.message === 'NOT_IN_GROUP'
                ? SocialsActions.setGroupLeaderFailedNotInGroup()
                : error?.message === 'NOT_LEADER_GROUP'
                  ? SocialsActions.setGroupLeaderFailedNotLeader()
                  : error?.message === 'ALREADY_LEADER_GROUP'
                    ? SocialsActions.setGroupLeaderFailedAlreadyLeader()
                    : SocialsActions.setGroupLeaderFailed();
            return of(action);
          }),
        ),
      ),
    ),
  );

  private _setGroupLeaderSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.setGroupLeaderSuccess),
        tap(({ leader }) => {
          this._notificationService.addNotification(
            'settings',
            `${leader.name} est chef de groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _setGroupLeaderFailedNotInGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        SocialsActions.setGroupLeaderFailedNotInGroup,
        SocialsActions.leaveGroupFailedNotInGroup,
      ),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          `Vous n'êtes pas dans un groupe`,
        );
      }),
      map(() => SocialsActions.groupDisbandSuccess()),
    ),
  );

  private _setGroupLeaderFailedNotLeader$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.setGroupLeaderFailedNotLeader),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Vous n'êtes pas le chef du groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _setGroupLeaderFailedAlreadyLeader$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.setGroupLeaderFailedAlreadyLeader),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            `Ce joueur est déjà chef du groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _setGroupLeaderFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.setGroupLeaderFailed),
        tap(() => {
          this._notificationService.addNotification(
            'error',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  private _leaveGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.leaveGroup),
      withLatestFrom(this._store.select(getPlayerID)),
      switchMap(([_, playerId]) =>
        this._socialsDbService.leaveGroup(playerId).pipe(
          map(() => SocialsActions.leaveGroupSuccess()),
          catchError(({ error }: HttpErrorResponse) =>
            of(
              error?.message === 'NOT_IN_GROUP'
                ? SocialsActions.leaveGroupFailedNotInGroup()
                : SocialsActions.leaveGroupFailed(),
            ),
          ),
        ),
      ),
    ),
  );

  private _leaveGroupSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.leaveGroupSuccess),
      tap(() => {
        this._notificationService.addNotification(
          'settings',
          'Vous avez quitté le groupe',
        );
      }),
      map(() => SocialsActions.groupDisbandSuccess()),
    ),
  );

  private _leaveGroupFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.leaveGroupFailed),
        tap(() => {
          this._notificationService.addNotification(
            'settings',
            'Une erreur est survenue, contactez un administrateur.',
          );
        }),
      ),
    { dispatch: false },
  );

  // TODO Remove le getgroup qui est un call lourd pour uniquement ajouter les infos du nouveu player invité
  private _playerJoinGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.playerJoinGroup),
      withLatestFrom(this._store.select(getPlayerName)),
      tap(([{ player }, playerName]) => {
        const text =
          player.name === playerName
            ? 'Vous avez rejoint le groupe'
            : `${player.name} a rejoint le groupe`;

        this._notificationService.addNotification('settings', text);
      }),
      map(([{ player }]) => SocialsActions.getGroup()),
    ),
  );

  private _playerLeftGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.playerLeftGroup),
      withLatestFrom(this._store.select(getPlayersGroupSize)),
      map(([{ name }, groupSize]) => {
        if (groupSize === 2) return SocialsActions.groupDisband();
        else return SocialsActions.playerLeftGroupSuccess({ name });
      }),
    ),
  );

  private _playerLeftGroupSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.playerLeftGroupSuccess),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'settings',
            `${name} a quitté le groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _kickGroupPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.kickGroupPlayer),
      withLatestFrom(
        this._store.select(getPlayerID),
        this._store.select(getPlayersGroupSize),
      ),
      switchMap(([{ name }, playerId, groupSize]) =>
        this._socialsDbService.kickGroupPlayer(playerId, name).pipe(
          map(() => {
            if (groupSize === 2) return SocialsActions.groupDisband();
            else return SocialsActions.kickGroupPlayerSuccess({ name });
          }),
          catchError(({ error }: HttpErrorResponse) =>
            of(
              error?.message === 'NOT_IN_GROUP'
                ? SocialsActions.setGroupLeaderFailedNotInGroup()
                : error?.message === 'NOT_LEADER_GROUP'
                  ? SocialsActions.setGroupLeaderFailedNotLeader()
                  : SocialsActions.kickGroupPlayerFailed(),
            ),
          ),
        ),
      ),
    ),
  );

  private _kickGroupPlayerSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SocialsActions.kickGroupPlayerSuccess),
        tap(({ name }) => {
          this._notificationService.addNotification(
            'settings',
            `${name} a été exclu du groupe`,
          );
        }),
      ),
    { dispatch: false },
  );

  private _kickFromGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.kickFromGroup),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          `Vous avez retiré du groupe.`,
        );
      }),
      map(() => SocialsActions.groupDisbandSuccess()),
    ),
  );

  private _groupDisband$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.groupDisband),
      tap(() => {
        this._notificationService.addNotification(
          'error',
          `Le groupe a été dissous.`,
        );
      }),
      map(() => SocialsActions.groupDisbandSuccess()),
    ),
  );

  private _groupDisbandSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SocialsActions.groupDisbandSuccess),
      withLatestFrom(this._store.select(getScopeChat)),
      filter(([_, scopeChat]) => scopeChat === ChatLogScope.Party),
      map(() => ChatActions.setScopeChat({ scopeChat: ChatLogScope.Local })),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _notificationService: NotificationManagerService,
    private readonly _socialsDbService: SocialsDbService,
    private readonly _store: Store,
  ) {}
}
