import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CharacterState } from '@t12/characters/store';
import { CharacterKind } from '@t12/common/characters/enums/character-kind.enum';
import { IMonster } from '@t12/common/characters/interfaces/monster.interface';
import { INPC } from '@t12/common/characters/interfaces/npc.interface';
import { IPlayer } from '@t12/common/characters/interfaces/player.interface';
import { EquipmentSlot } from '@t12/common/equipments/types/equipment-slot.type';
import { positionInFrontClose } from '@t12/world/constants/position-in-front-close.constant';

const selectRoot = createFeatureSelector<CharacterState>('characters');

export const getCharacters = createSelector(
  selectRoot,
  (characters: CharacterState) => characters,
);

export const getCharacterById = (id: number, kind: CharacterKind) =>
  createSelector(getCharacters, (characters: CharacterState) => {
    return characters.find(
      (character) => character.id === id && character.kind === kind,
    );
  });

export const getCharacterByCode = (codeCharacter: string | undefined) =>
  createSelector(getCharacters, (characters: CharacterState) => {
    if (!codeCharacter) return undefined;

    return characters.find(
      (character) => (character as INPC | IMonster).code === codeCharacter,
    ) as INPC | IMonster | undefined;
  });

export const getCharacterInFrontOfPlayer = createSelector(
  selectRoot,
  (characters: CharacterState) => {
    if (!characters[0]) return undefined;
    const player = characters[0];

    const { looking, position: positionPlayer } = player;
    const position = positionInFrontClose(
      positionPlayer.x,
      positionPlayer.y,
      looking,
    );
    return characters.find(
      ({ position: { x, y } }) => x === position.x && y === position.y,
    );
  },
);

export const getPlayer = createSelector(
  selectRoot,
  (characters: CharacterState) => characters[0],
);

export const getPlayerID = createSelector(
  selectRoot,
  (characters: CharacterState) => characters[0]?.id,
);

export const getPlayerHealthManaLvlByID = (id: number) =>
  createSelector(getCharacters, (characters: CharacterState) => {
    const player = characters.find(
      (character) =>
        character.id === id && character.kind === CharacterKind.PLAYER,
    );
    return player
      ? { health: player.health, mana: player.mana, lvl: player.lvl }
      : undefined;
  });

export const getPlayerName = createSelector(
  selectRoot,
  (characters: CharacterState) => characters[0]?.name,
);

export const isLocalPlayerById = (id: number) =>
  createSelector(getPlayerID, (playerId) => playerId === id);

export const isLocalPlayerByName = (name: string) =>
  createSelector(getPlayerName, (playerName) => playerName === name);

export const getPlayerPosition = createSelector(
  selectRoot,
  (characters: CharacterState) => characters[0]?.position,
);

export const getPlayerPositionInFront = createSelector(
  selectRoot,
  (characters: CharacterState) =>
    positionInFrontClose(
      characters[0]?.position.x,
      characters[0]?.position.y,
      characters[0]?.looking,
    ),
);

export const getPlayerLvl = createSelector(
  selectRoot,
  (characters: CharacterState) => characters[0]?.lvl,
);

export const getPlayerStats = createSelector(
  selectRoot,
  (characters: CharacterState) => characters[0]?.stats,
);

export const getPlayerEquipments = createSelector(
  selectRoot,
  (characters: CharacterState) => characters[0]?.equipments,
);

export const getPlayerEquipmentBySlot = (slot: EquipmentSlot) =>
  createSelector(getPlayerEquipments, (equipments) =>
    equipments.find((equipment) => equipment.slot === slot),
  );

export const getPlayerWorldCode = createSelector(
  selectRoot,
  (characters: CharacterState) => (characters[0] as IPlayer)?.worldCode,
);

export const getPlayerGold = createSelector(
  selectRoot,
  (characters: CharacterState) => characters[0]?.gold,
);

export const getPlayerJobs = createSelector(
  selectRoot,
  (characters: CharacterState) => characters[0]?.jobs,
);
